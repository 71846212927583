import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { useVideoContext } from "../../../contexts/VideoContext";
import { User, Gameplay } from "../../../types";
import { useAuth } from "../../../providers/userAuth";
import {
  AvatarIcon,
  UnVerifiedIconFrame,
  RepostIcon,
  CommentIcon,
  LikeIcon,
  ShareIcon,
  LinkIcon,
  DotsIconFrameGameplay,
  PostOptions,
} from "../..";
import {
  likeGameplay,
  unLikeGameplay,
  repostGameplay,
  unRepostGameplay,
} from "../../../helpers/fetchGameplay";
import { formatDate } from "../../../utils/dateUtils";

interface GameplayCardProps {
  gameplay: Gameplay;
  userwhoreposted?: string;
  disableNavigation?: boolean;
  showInteractions?: boolean;
  repostedGameplays?: boolean;
  likedGameplays?: boolean;
  bottomBorder?: boolean;
  onDeleteGameplay?: (gameplayId: string) => void;
}

export const GameplayCard: React.FC<GameplayCardProps> = ({
  gameplay,
  userwhoreposted,
  disableNavigation = false,
  showInteractions = true,
  repostedGameplays = false,
  likedGameplays = false,
  bottomBorder = true,
  onDeleteGameplay,
}) => {
  const navigate = useNavigate();
  const auth = useAuth();
  const { activeVideoId, setActiveVideoId } = useVideoContext();
  const [user, setUser] = useState<User>();
  const [isLiked, setIsLiked] = useState(gameplay.isLiked ?? false);
  const [isReposted, setIsReposted] = useState(gameplay.isReposted ?? false);
  const [likeCount, setLikeCount] = useState(gameplay.likeCount ?? 0);
  const [repostCount, setRepostCount] = useState(gameplay.repostCount ?? 0);
  const [showOverlay, setShowOverlay] = useState(false);
  const [showShareOverlay, setShowShareOverlay] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);
  const [showPostOptions, setShowPostOptions] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const overlayRef = useRef<HTMLDivElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const likeTimeout = useRef<NodeJS.Timeout | null>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const { ref, inView } = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    setUser(auth.user);
  }, [auth]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        overlayRef.current &&
        !overlayRef.current.contains(event.target as Node)
      ) {
        setShowOverlay(false);
        setShowShareOverlay(false);
        setIsDisabled(true);
        setTimeout(() => setIsDisabled(false), 10);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [overlayRef]);

  useEffect(() => {
    if (inView) {
      setActiveVideoId(gameplay.id);
    }
  }, [inView, gameplay.id, setActiveVideoId]);

  useEffect(() => {
    if (activeVideoId === gameplay.id) {
      const playPromise = videoRef.current?.play();
      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            setIsPlaying(true);
          })
          .catch((error) => {
            console.error("Failed to play video:", error);
          });
      }
    } else {
      videoRef.current?.pause();
      setIsPlaying(false);
    }
  }, [activeVideoId, gameplay.id]);

  const handleLike = () => {
    if (isDisabled) return;

    const previousLikedState = isLiked;
    const previousLikeCount = likeCount;

    setIsLiked(!previousLikedState);
    setLikeCount(previousLikedState ? likeCount - 1 : likeCount + 1);

    if (likeTimeout.current) {
      clearTimeout(likeTimeout.current);
    }

    likeTimeout.current = setTimeout(async () => {
      try {
        if (previousLikedState) {
          await unLikeGameplay(gameplay.id);
        } else {
          await likeGameplay(gameplay.id);
        }
      } catch (error) {
        console.error("Error toggling like:", error);
        setIsLiked(previousLikedState);
        setLikeCount(previousLikeCount);
      }
    }, 500);
  };

  const handleRepost = async () => {
    try {
      if (isReposted) {
        await unRepostGameplay(gameplay.id);
        setIsReposted(false);
        setRepostCount((prev) => Math.max(prev - 1, 0));
      } else {
        await repostGameplay(gameplay.id);
        setIsReposted(true);
        setRepostCount((prev) => prev + 1);
      }
      setShowOverlay(false);
      setIsDisabled(true);
      setTimeout(() => setIsDisabled(false), 10);
    } catch (error) {
      console.error("Error toggling repost:", error);
    }
  };

  const handleCopyLink = (e: React.MouseEvent) => {
    e.stopPropagation();

    const link = `${window.location.origin}/gameplay/${gameplay.id}`;
    navigator.clipboard.writeText(link).then(() => {
      setLinkCopied(true);
    });
    setIsDisabled(true);
  };

  const encodeUsername = (username: string) => {
    return encodeURIComponent(
      username.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
    );
  };

  const navigatePlayerPage = (username: string) => {
    navigate(`/user/${encodeUsername(username)}`);
  };

  const navigateUserReposted = () => {
    navigate(`/user/${userwhoreposted}`);
  };

  const navigateGamePage = () => {
    navigate(`/game/${gameplay.game.id}/${gameplay.game.name}`);
  };

  const navigateToGameplay = () => {
    if (isDisabled) return;
    if (!disableNavigation) {
      navigate(`/play/${gameplay.id}`);
    }
  };

  const handleDeleteGameplay = () => {
    if (onDeleteGameplay) {
      onDeleteGameplay(gameplay.id);
    }
  };

  return (
    <div
      className={`flex w-full max-w-[610px] flex-col gap-y-1 rounded-2xl bg-[rgba(255,255,255,0.10)] ${
        bottomBorder && "border-b border-b-[rgba(245,235,255,0.10)]"
      } pb-3
        ${disableNavigation ? "" : "cursor-pointer rounded"} ${
          disableNavigation || isDisabled ? "" : ""
        }`}
      onClick={navigateToGameplay}
      ref={ref}
    >
      {isDisabled && <div className="fixed inset-0 z-[9] bg-transparent"></div>}
      {repostedGameplays && gameplay.user && (
        <div className="flex items-center gap-x-2 px-4 opacity-50">
          <RepostIcon />
          <p className="label-s-600">{userwhoreposted} a reposté</p>
        </div>
      )}
      {likedGameplays && gameplay.user && (
        <div className="flex items-center gap-x-2 px-4 opacity-50">
          <LikeIcon />
          <p className="label-s-600">{userwhoreposted} a liké</p>
        </div>
      )}

      <div className="relative flex w-full justify-center rounded-lg pt-4">
        <video
          controls
          src={gameplay.file}
          className="mb-1 w-[calc(100%-32px)] transform cursor-pointer rounded-lg shadow-[4px_4px_4px_rgba(0,0,0,0.30)] transition-transform hover:scale-[102%]"
          ref={videoRef}
          muted
          playsInline
          onPlay={() => setIsPlaying(true)}
          onPause={() => setIsPlaying(false)}
        />
        {!isPlaying && (
          <div className="absolute top-[30px] flex w-full justify-end gap-x-3 px-6">
            <div className="flex items-end">
              {auth.user?.id === gameplay.user.id && (
                <div
                  className={`relative z-[100] mt-[-12px] flex w-[30px] flex-col items-center justify-center px-1.5 py-1 ${
                    showPostOptions ? "" : "cursor-pointer"
                  }`}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowPostOptions(!showPostOptions);
                  }}
                >
                  <DotsIconFrameGameplay />
                  {showPostOptions && (
                    <PostOptions
                      userId={gameplay.user.id}
                      name={gameplay.user.username}
                      gameplayId={gameplay.id}
                      isUserPost={auth.user?.id === gameplay.user.id}
                      onClose={() => setShowPostOptions(false)}
                      onDeletePost={handleDeleteGameplay}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="flex w-full items-center justify-between gap-x-2">
        <p className="h2-mobile h-auto px-4">{gameplay.name}</p>
      </div>
      <div className="flex w-full items-center gap-x-4 px-4">
        <div
          className="relative cursor-pointer pt-1"
          onClick={(e) => e.stopPropagation()}
        >
          <AvatarIcon
            logo={gameplay.user.logo}
            className="avatar-s"
            onClick={() => navigatePlayerPage(gameplay.user.username)}
          />
          {gameplay.game ? (
            <div
              className="game-thumbnail-xxs absolute bottom-[-1px] left-[28px] border-[0.5px] border-[rgba(198,182,213,0.50)] shadow-[-1px_-1px_4px_rgba(0,0,0,0.25)] hover:border-[rgba(255,255,255,0.6)]"
              onClick={navigateGamePage}
            >
              <div
                className="z-1 flex h-[22px] w-[22px] items-start rounded-[4px]"
                style={{
                  background: `url(${gameplay.game.logo}) 50% / cover no-repeat`,
                }}
              ></div>
            </div>
          ) : null}
        </div>
        <div className="flex w-full min-w-[80%] flex-col justify-center">
          <div className="flex items-center self-stretch">
            <div className="flex w-full items-center justify-between gap-x-2">
              <div
                className="flex w-full max-w-[85%] items-center gap-x-2"
                onClick={(e) => e.stopPropagation()}
              >
                {gameplay.user.pseudo ? (
                  <p
                    className="h4-mobile whitespace-nowrap opacity-90 hover:text-[#F78888]"
                    onClick={() => navigatePlayerPage(gameplay.user.username)}
                  >
                    {gameplay.user.pseudo}
                  </p>
                ) : (
                  <p
                    className="h4-mobile opacity-90 hover:text-[#F78888]"
                    onClick={() => navigatePlayerPage(gameplay.user.username)}
                  >
                    @{gameplay.user.username}
                  </p>
                )}
                {gameplay.taggedUsers && gameplay.taggedUsers.length > 0 && (
                  <div className="truncate-one-line flex w-full items-center gap-x-2">
                    {gameplay.taggedUsers
                      // Filtrer pour exclure l'utilisateur qui a posté le gameplay
                      .filter(
                        (taggedUser, index, self) =>
                          taggedUser.id !== gameplay.user.id &&
                          self.findIndex((u) => u.id === taggedUser.id) ===
                            index,
                      )
                      .map((taggedUser, index, array) => (
                        <React.Fragment key={taggedUser.id}>
                          <p
                            className="label-s-400 cursor-pointer whitespace-nowrap opacity-90 hover:text-[#F78888]"
                            onClick={() =>
                              navigatePlayerPage(taggedUser.username)
                            }
                          >
                            + {taggedUser.pseudo || `@${taggedUser.username}`}
                          </p>
                          {index < array.length - 1 && (
                            <span className="text-gray-400">+</span>
                          )}
                        </React.Fragment>
                      ))}
                  </div>
                )}
              </div>
              <div className="flex items-center self-stretch">
                <p className="label-s-400 whitespace-nowrap rounded-lg bg-[rgba(0,0,0,0.4)] p-1">
                  Play
                </p>
              </div>
            </div>
          </div>

          <div
            className={`flex w-full ${
              gameplay.game ? "justify-between" : "justify-end"
            } gap-x-2`}
            onClick={(e) => e.stopPropagation()}
          >
            {gameplay.game ? (
              <p
                className="truncate-one-line label-s-400 cursor-pointer self-stretch opacity-50 hover:underline"
                onClick={navigateGamePage}
              >
                {gameplay.game.name}
              </p>
            ) : null}
            <p className="label-s-400 whitespace-nowrap opacity-40">
              {formatDate(gameplay.created_at)}
            </p>
          </div>
        </div>
      </div>

      {showInteractions && (
        <div className="z-[10] flex items-center justify-between self-stretch px-4 pt-4 opacity-90">
          <div
            className="flex cursor-pointer items-center gap-2"
            onClick={(e) => e.stopPropagation()}
          >
            <CommentIcon onClick={navigateToGameplay} />
            <span className="label-s-400 text-xs" onClick={navigateToGameplay}>
              {gameplay.comments?.length ?? 0}
            </span>
          </div>
          <div
            className="flex cursor-pointer items-center gap-2"
            onClick={(e) => {
              e.stopPropagation();
              handleLike();
            }}
          >
            <LikeIcon onClick={handleLike} liked={isLiked} />
            <span
              className={`label-s-400 cursor-pointer text-xs ${
                isLiked ? "text-[#F78888]" : ""
              }`}
            >
              {likeCount}
            </span>
          </div>
          <div
            className="relative z-[10] flex cursor-pointer items-center gap-2"
            onClick={(e) => {
              e.stopPropagation();
              setShowOverlay(!showOverlay);
              setIsDisabled(true);
            }}
          >
            <RepostIcon reposted={isReposted} />
            <span
              className={`label-s-400 cursor-pointer text-xs ${
                isReposted ? "text-[rgba(0,186,124)]" : ""
              }`}
            >
              {repostCount}
            </span>
            {showOverlay && (
              <div
                ref={overlayRef}
                className="absolute right-0 top-[-50px] z-[1000] flex w-[200px] flex-col items-start gap-y-3 rounded-lg border-[0.5px] border-[rgba(143,142,142,0.50)] px-4 py-3 backdrop-blur-[6.5px]"
                style={{
                  background:
                    "linear-gradient(293deg, rgba(40, 12, 49, 1) 0.12%, rgba(151, 46, 182, 1) 117.69%)",
                }}
                onClick={(e) => e.stopPropagation()}
              >
                <div
                  className="flex cursor-pointer items-center gap-x-[10px]"
                  onClick={handleRepost}
                >
                  <RepostIcon />
                  <p className="nav-menu-500">
                    {isReposted ? "Annuler la republication" : "Republier"}
                  </p>
                </div>
              </div>
            )}
          </div>
          <div
            className="relative z-[100] flex cursor-pointer items-center"
            onClick={(e) => {
              e.stopPropagation();
              setShowShareOverlay(!showShareOverlay);
              setIsDisabled(true);
            }}
          >
            <ShareIcon />
            {showShareOverlay && (
              <div
                ref={overlayRef}
                className="absolute right-0 top-[-50px] z-[1000] flex w-[200px] flex-col items-start gap-y-3 rounded-lg border-[0.5px] border-[rgba(143,142,142,0.50)] px-4 py-3 backdrop-blur-[6.5px]"
                style={{
                  background:
                    "linear-gradient(293deg, rgba(40, 12, 49, 1) 0.12%, rgba(151, 46, 182, 1) 117.69%)",
                }}
                onClick={(e) => e.stopPropagation()}
              >
                <div
                  className="flex cursor-pointer items-center gap-x-[10px]"
                  onClick={handleCopyLink}
                >
                  <LinkIcon />
                  <p className="nav-menu-500">
                    {linkCopied ? "Lien copié" : "Copier le lien"}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

interface CloseFillIconProps {
  rotated?: boolean;
  size?: 10 | 12 | 14;
  onClick?: () => void;
  hoverEffect?: boolean;
  pointer?: boolean;
}

export const CloseFillIcon: React.FC<CloseFillIconProps> = ({
  rotated = false,
  size = 14,
  onClick,
  hoverEffect = true,
  pointer = false,
}) => {
  const renderSvg = () => {
    const baseClass = "transition-transform duration-200";
    const hoverClass = hoverEffect ? "group-hover:scale-110" : "";
    const combinedClass = `${baseClass} ${hoverClass}`;

    switch (size) {
      case 10:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 10 11"
            fill="none"
            style={{
              transform: rotated ? "rotate(45deg)" : "none",
            }}
            className={combinedClass}
          >
            <path
              d="M5.14451 0.5H4.91329C4.43443 0.5 4.04624 0.888192 4.04624 1.36705V4.48844H0.867052C0.388192 4.48844 0 4.87663 0 5.35549V5.5867C0 6.06556 0.388192 6.45376 0.867052 6.45376H4.04624V9.63295C4.04624 10.1118 4.43443 10.5 4.91329 10.5H5.14451C5.62337 10.5 6.01156 10.1118 6.01156 9.63295V6.45376L9.13295 6.45376C9.61181 6.45376 10 6.06556 10 5.5867V5.35549C10 4.87663 9.61181 4.48844 9.13295 4.48844L6.01156 4.48844V1.36705C6.01156 0.888192 5.62337 0.5 5.14451 0.5Z"
              fill="#F8EFFB"
            />
          </svg>
        );
      case 12:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 14 14"
            fill="none"
            className={`cursor-pointer ${combinedClass}`}
            style={{
              transform: rotated ? "rotate(45deg)" : "none",
            }}
          >
            <path
              d="M13 1L7 7M7 7L1 13M7 7L1 1M7 7L13 13"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        );
      case 14:
      default:
        return (
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={`cursor-pointer ${combinedClass}`}
            style={{
              transform: rotated ? "rotate(45deg)" : "none",
            }}
          >
            <path
              d="M13 1L7 7M7 7L1 13M7 7L1 1M7 7L13 13"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        );
    }
  };

  return (
    <div
      onClick={onClick}
      className={`group relative -m-2 inline-flex ${pointer ? "cursor-pointer" : ""} items-center p-2 ${hoverEffect ? "group-hover:bg-[rgba(29,155,240,0.5)] group-hover:bg-opacity-100" : ""}`}
    >
      <div
        className={`absolute inset-0 rounded-full bg-transparent transition-transform duration-200 ${hoverEffect ? "group-hover:bg-[rgba(29,155,240,0.25)] group-hover:bg-opacity-100" : ""}`}
      ></div>
      {renderSvg()}
    </div>
  );
};

export const CloseIconFrame = () => {
  return (
    <div
      className="inline-flex h-[34px] w-[34px] flex-col items-center justify-center rounded-[7px] border-[0.3px] border-solid border-white bg-[#ffffff40] px-[6px] backdrop-blur-[2xp]
"
    >
      <CloseFillIcon />
    </div>
  );
};

export const CrossAddWhiteIcon = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.25"
      y="0.25"
      width="25.5"
      height="25.5"
      rx="12.75"
      fill="white"
    />
    <rect
      x="0.25"
      y="0.25"
      width="25.5"
      height="25.5"
      rx="12.75"
      stroke="#F8EFFB"
      strokeWidth="0.5"
    />
    <path
      d="M13.1445 8H12.9133C12.4344 8 12.0462 8.38819 12.0462 8.86705V11.9884H8.86705C8.38819 11.9884 8 12.3766 8 12.8555V13.0867C8 13.5656 8.38819 13.9538 8.86705 13.9538H12.0462V17.1329C12.0462 17.6118 12.4344 18 12.9133 18H13.1445C13.6234 18 14.0116 17.6118 14.0116 17.1329V13.9538L17.1329 13.9538C17.6118 13.9538 18 13.5656 18 13.0867V12.8555C18 12.3766 17.6118 11.9884 17.1329 11.9884L14.0116 11.9884V8.86705C14.0116 8.38819 13.6234 8 13.1445 8Z"
      fill="#0842A4"
    />
  </svg>
);

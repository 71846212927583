import React, { useState, useEffect, useCallback } from "react";
import debounce from "lodash.debounce";
import { searchUsers } from "../../helpers/fetchUser";
import { AvatarIcon } from "../";
import { UserSearchResult } from "../../types";
import { IoMdClose } from "react-icons/io";

interface PlayerSearchProps {
  gameId: string;
  onSelectUsers: (userIds: string[]) => void;
}

export const PlayerSearch: React.FC<PlayerSearchProps> = ({
  gameId,
  onSelectUsers,
}) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchResults, setSearchResults] = useState<UserSearchResult[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<UserSearchResult[]>([]);

  const debouncedSearch = useCallback(
    debounce(async (query: string) => {
      if (query) {
        try {
          const results = await searchUsers(query, gameId);
          setSearchResults(results.usersByPseudos);
        } catch (error) {
          console.error("Error searching users:", error);
        }
      } else {
        setSearchResults([]);
      }
    }, 400),
    [gameId],
  );

  useEffect(() => {
    debouncedSearch(searchQuery);
  }, [searchQuery]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleUserSelect = (user: UserSearchResult) => {
    if (!selectedUsers.find((u) => u.id === user.id)) {
      const newSelectedUsers = [...selectedUsers, user];
      setSelectedUsers(newSelectedUsers);
      onSelectUsers(newSelectedUsers.map((u) => u.id));
    }
  };

  const handleUserRemove = (userId: string) => {
    const newSelectedUsers = selectedUsers.filter((user) => user.id !== userId);
    setSelectedUsers(newSelectedUsers);
    onSelectUsers(newSelectedUsers.map((u) => u.id));
  };

  return (
    <div className="styled-scrollbars w-full">
      <input
        type="text"
        placeholder="Rechercher des joueurs"
        value={searchQuery}
        onChange={handleInputChange}
        className="w-full rounded border border-[#ced4da] !bg-[#3a2144] p-2 text-white"
      />
      <div className="mt-2 max-h-40 overflow-y-auto">
        {searchResults.map((user) => (
          <div
            key={user.id}
            className="flex cursor-pointer items-center gap-x-2 p-2 hover:bg-[rgba(255,255,255,0.1)]"
            onClick={() => handleUserSelect(user)}
          >
            <AvatarIcon logo={user.logo} className="avatar-xs" />
            <div>
              <p className="label-m-400">@{user.username}</p>
              <p className="label-m-400">{user.name}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-2 flex flex-wrap gap-2">
        {selectedUsers.map((user) => (
          <div
            key={user.id}
            className="flex items-center gap-x-2 rounded bg-white p-2"
          >
            <AvatarIcon logo={user.logo} className="avatar-xs" />
            <span className="label-m-700 text-black">{user.name}</span>
            <button
              onClick={() => handleUserRemove(user.id)}
              className="ml-auto"
            >
              <IoMdClose />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

// UserGameplayPage.tsx

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchUserGameplay } from "../../helpers/fetchUser";
import {
  ButtonSimple,
  GameplayPreviewCard,
  SubtitleNavigation,
} from "../../components";
import { Gameplay } from "../../types";
import { useAuth } from "../../providers/userAuth";
import { UserLayout } from "./UserLayout";
import { useMediaQuery } from "react-responsive";
import { capitalizeFirstLetter } from "../../utils/stringUtils"; // Import de la fonction d'assistance

const postButtons = [
  { title: "Date", path: "", exact: true },
  { title: "Jeux", path: "jeux", exact: false },
];

// Fonction pour regrouper les gameplays par mois et capitaliser le nom du mois
const groupGameplaysByMonth = (gameplays: Gameplay[]) => {
  const grouped: { [key: string]: Gameplay[] } = {};

  gameplays.forEach((gameplay) => {
    const date = new Date(gameplay.created_at);
    let monthYear = date.toLocaleString("fr-FR", {
      year: "numeric",
      month: "long",
    });

    // Capitaliser la première lettre du mois
    monthYear = capitalizeFirstLetter(monthYear);

    if (!grouped[monthYear]) {
      grouped[monthYear] = [];
    }
    grouped[monthYear].push(gameplay);
  });

  return grouped;
};

export const UserGameplayPage = () => {
  const { username } = useParams();
  const navigate = useNavigate();
  const auth = useAuth();
  const [gameplays, setGameplays] = useState<Gameplay[]>([]);
  const [filteredGameplays, setFilteredGameplays] = useState<Gameplay[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<string>("Date");
  const [games, setGames] = useState<string[]>(["Tous"]);
  const isMobile = useMediaQuery({ maxWidth: 745 });

  useEffect(() => {
    const fetchGameplays = async () => {
      if (username) {
        try {
          const userGameplays = await fetchUserGameplay(username)();
          userGameplays.sort(
            (a, b) =>
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime(),
          );
          setGameplays(userGameplays);
          setFilteredGameplays(userGameplays);
          const gameNames = [
            "Tous",
            ...Array.from(new Set(userGameplays.map((g) => g.game.name))),
          ];
          setGames(gameNames);
        } catch (error) {
          console.error("Erreur lors de la récupération des gameplays", error);
        }
      }
    };

    fetchGameplays();
  }, [username]);

  const isOwner = auth.user?.username === username;

  const handleDeleteGameplay = (gameplayId: string) => {
    setGameplays((prevGameplays) =>
      prevGameplays.filter((gameplay) => gameplay.id !== gameplayId),
    );
    setFilteredGameplays((prevFilteredGameplays) =>
      prevFilteredGameplays.filter((gameplay) => gameplay.id !== gameplayId),
    );
  };

  const handleNavigation = (path: string) => {
    setSelectedFilter(path ? "Jeux" : "Date");
    if (path === "jeux") {
      navigate(`/user/${username}/plays/jeux`);
    } else {
      setFilteredGameplays(
        gameplays.sort(
          (a, b) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
        ),
      );
      navigate(`/user/${username}/plays${path ? `/${path}` : ""}`);
    }
  };

  const groupedGameplays = groupGameplaysByMonth(filteredGameplays);

  return (
    <UserLayout>
      <div
        className="flex h-full w-full flex-col items-center gap-y-4 px-5"
        style={{
          background: isMobile
            ? "linear-gradient(0deg, #140029 61.19%, #1A0920 100%)"
            : "none",
        }}
      >
        <div className="flex items-start gap-x-2 max-mobile:pt-2">
          <SubtitleNavigation
            buttons={postButtons.map((button) => ({
              ...button,
              path: `/user/${username}/plays${button.path ? `/${button.path}` : ""}`,
            }))}
            secondary={true}
            padding="p-0"
            // activeButton={selectedFilter} // Assurez-vous de supprimer cette ligne si vous avez suivi les précédentes suggestions
          />
        </div>
        <div className="flex w-full flex-col items-center pb-8 max-mobile:pt-4 max-tablet:gap-y-8">
          <div className="flex w-full flex-col items-center gap-y-4 pb-10 max-tablet:max-w-[610px]">
            {Object.keys(groupedGameplays).length > 0 ? (
              Object.entries(groupedGameplays).map(
                ([monthYear, monthGameplays]) => (
                  <div key={monthYear} className="flex w-full flex-col gap-y-4">
                    <h2 className="nav-menu-700 max-mobile:text-center">
                      {monthYear}
                    </h2>
                    <div className="flex flex-wrap items-center justify-start gap-3 max-mobile:justify-center">
                      {monthGameplays.map((gameplay) => (
                        <GameplayPreviewCard
                          key={gameplay.id}
                          gameplay={gameplay}
                          autoPlay={false}
                          bottomBorder={false}
                        />
                      ))}
                    </div>
                  </div>
                ),
              )
            ) : (
              <div className="flex flex-col items-center gap-y-4">
                <p className="paragraph-m-400">
                  {isOwner
                    ? "Vous n'avez encore rien publié. Commencez par partager un gameplay!"
                    : "Cet utilisateur n'a encore rien publié."}
                </p>
                {isOwner && (
                  <ButtonSimple
                    label="Partager un gameplay"
                    onClick={() => navigate("/creategameplay")}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </UserLayout>
  );
};

import axios, { AxiosResponse } from "axios";
import {
  commentGameplayUrl,
  createGameplayUrl,
  deleteGameplayUrl,
  fetchGameplayTimelineUrl,
  likeGameplayUrl,
  repostGameplayUrl,
  unLikeGameplayUrl,
  unRepostGameplayUrl,
} from "../constants/api";
import { Gameplay } from "../types";

interface GameplayResponse {
  game_id: string;
  comment: string;
  name: string;
  user_id: string;
  id: string;
  created_at: string;
  file: string;
}

export const createGameplay = async (
  name: string,
  comment: string,
  file: File,
  gameId: string,
  userIds: string[],
): Promise<GameplayResponse> => {
  const authToken = localStorage.getItem("token");
  if (!authToken) {
    console.error("Auth token not found");
    throw new Error("Auth token not found");
  }

  const formData = new FormData();
  formData.append("name", name);
  formData.append("comment", comment);
  formData.append("gameId", gameId);
  formData.append("file", file);
  userIds.forEach((userId, index) =>
    formData.append(`userIds[${index}]`, userId),
  ); // Append userIds

  const response = await axios.post<GameplayResponse>(
    createGameplayUrl,
    formData,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
    },
  );
  return response.data;
};
export const fetchGameplayTimeline = async (): Promise<Gameplay[]> => {
  const authToken = localStorage.getItem("token");
  if (!authToken) {
    console.error("Auth token not found");
    throw new Error("Auth token not found");
  }

  try {
    const response = await axios.get<Gameplay[]>(fetchGameplayTimelineUrl, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching gameplay:", error);
    throw error;
  }
};

export const fetchOneGameplay =
  (gameplayId: string) => async (): Promise<Gameplay> => {
    const authToken = localStorage.getItem("token");
    if (!authToken) {
      console.error("Auth token not found");
      throw new Error("Auth token not found");
    }

    try {
      const response: AxiosResponse<Gameplay> = await axios.get(
        createGameplayUrl + gameplayId,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        },
      );

      const postData: Gameplay = response.data;
      return postData;
    } catch (error) {
      console.error("Error fetching post:", error);
      throw error;
    }
  };

export const createCommentGameplay = async (
  gameplayId: string,
  comment: string,
): Promise<void> => {
  const authToken = localStorage.getItem("token");
  if (!authToken) {
    console.error("Auth token not found");
    throw new Error("Auth token not found");
  }

  const formData = new FormData();
  formData.append("comment", comment);

  try {
    const response: AxiosResponse<void> = await axios.post(
      `${commentGameplayUrl}${gameplayId}`,
      formData,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
  } catch (error) {
    console.error("Error creating comment on gameplay:", error);
    throw error;
  }
};

export const likeGameplay = async (gameplayId: string): Promise<void> => {
  const authToken = localStorage.getItem("token");
  if (!authToken) {
    console.error("Auth token not found");
    throw new Error("Auth token not found");
  }

  const response: AxiosResponse<void> = await axios.post<void>(
    likeGameplayUrl + gameplayId,
    { gameplayId },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    },
  );
  return response.data;
};

export const unLikeGameplay = async (gameplayId: string): Promise<void> => {
  const authToken = localStorage.getItem("token");
  if (!authToken) {
    console.error("Auth token not found");
    throw new Error("Auth token not found");
  }

  const response: AxiosResponse<void> = await axios.delete<void>(
    unLikeGameplayUrl + gameplayId,
    {
      data: { gameplayId },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    },
  );
  return response.data;
};

export const repostGameplay = async (gameplayId: string): Promise<void> => {
  const authToken = localStorage.getItem("token");
  if (!authToken) {
    console.error("Auth token not found");
    throw new Error("Auth token not found");
  }

  const response: AxiosResponse<void> = await axios.post<void>(
    repostGameplayUrl + gameplayId,
    { gameplayId },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    },
  );
  return response.data;
};

export const unRepostGameplay = async (gameplayId: string): Promise<void> => {
  const authToken = localStorage.getItem("token");
  if (!authToken) {
    console.error("Auth token not found");
    throw new Error("Auth token not found");
  }

  const response: AxiosResponse<void> = await axios.delete<void>(
    unRepostGameplayUrl + gameplayId,
    {
      data: { gameplayId },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    },
  );
  return response.data;
};

export const deleteGameplay = async (gameplayId: string): Promise<void> => {
  const authToken = localStorage.getItem("token");
  if (!authToken) {
    console.error("Auth token not found");
    throw new Error("Auth token not found");
  }

  const response: AxiosResponse<void> = await axios.delete<void>(
    deleteGameplayUrl + gameplayId,
    {
      data: { gameplayId },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    },
  );
  return response.data;
};

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  SecondaryButton,
  LogoBeta,
  DiscordIcon22,
  BurgerMenuIcon,
  ChevronLandingPage,
  GameCoverColumn,
  LinkedInIcon,
  CloseFillIcon,
} from "../../components";
import LandingChat from "../../assets/Landing_Chat.png";
import LandingAchievement from "../../assets/Landing_Achievement.png";
import LandingGamepad from "../../assets/Landing_Gamepad.png";
import BG_PC from "../../assets/Landing_BG_PC.png";
import BG_TB from "../../assets/Landing_BG_TB.png";
import EpicGames from "../../assets/EpicGames.png";
import Steam from "../../assets/Steam.png";
import Playstation from "../../assets/Playstation.png";
import Supercell from "../../assets/Supercell.png";
import RiotGames from "../../assets/RiotGames.png";
import ConnectGamesMobile from "../../assets/ConnectGamesMobile.png";
import ConnectGamesDesktop from "../../assets/ConnectGamesDesktop.png";
import ConnectGamesDesktop2 from "../../assets/ConnectGamesDesktop2.png";
import LandingCoc from "../../assets/Landing_CoCPlay.png";
import LandingCS2 from "../../assets/Landing_CS2Play.png";
import LandingLol from "../../assets/Landing_LolPlay.jpg";
import CoverCoC from "../../assets/CoverCoC.png";
import CoverFortnite from "../../assets/CoverFortnite.png";
import CoverLoL from "../../assets/CoverLoL.png";
import CoverValorant from "../../assets/CoverValorant.png";
import CoverRL from "../../assets/CoverRL.png";
import ClanDiscussion from "../../assets/ClanDiscussion.png";
import COCScreen from "../../assets/COCScreen.jpg";
import BottomBanner from "../../assets/BottomBanner.png";
import GameplayBG from "../../assets/Gameplay_BG.png";

export const LandingPage = () => {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const navigateToSignIn = () => {
    navigate(`/signin`);
  };

  const handleClick = () => {
    window.open(
      "https://discord.gg/bfYqH8jB8W",
      "_blank",
      "noopener,noreferrer",
    );
  };

  const navigateToSignUp = () => {
    navigate(`/inscription`);
  };

  const navigateHome = () => {
    navigate(`/home`);
  };

  const navigateTerms = () => {
    navigate(`/legal/terms`);
  };

  const navigatePrivacy = () => {
    navigate(`/legal/privacy`);
  };

  const [carouselImages, setCarouselImages] = useState([
    LandingCoc, // Gauche
    LandingCS2, // Centre
    LandingLol, // Droite
  ]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Fonction pour gérer le clic sur une image du carrousel
  const handleCarouselClick = (clickedImage) => {
    // Si l'image cliquée est déjà au centre, ne rien faire
    if (carouselImages[1] === clickedImage) return;

    // Réorganiser les images pour placer l'image cliquée au centre
    const newOrder = carouselImages.filter((img) => img !== clickedImage);
    setCarouselImages([newOrder[0], clickedImage, newOrder[1]]);
  };

  return (
    <div className="relative flex h-full w-full flex-col overflow-x-hidden bg-[#280C31]">
      {/* Image de fond */}
      <img
        src={BG_PC}
        alt="BG icon"
        className="absolute left-0 top-0 z-0 h-full max-h-[1200px] w-full object-cover max-825:hidden"
      />
      <img
        src={BG_TB}
        alt="BG icon"
        className="absolute left-0 top-0 z-0 h-full max-h-[1200px] w-full object-cover min-825:hidden"
      />
      {/* Contenu principal */}
      <div className="relative z-10 flex flex-grow flex-col">
        {/* Header */}
        <header className="z-10 flex w-full justify-between px-4 py-4 min-825:px-[88px]">
          <LogoBeta />
          {/* Bouton Burger Menu visible uniquement sur les petits écrans */}
          <div className="min-1050:hidden">
            <BurgerMenuIcon onClick={toggleMenu} />
          </div>
          {/* Menu de navigation visible uniquement sur les grands écrans */}
          <div className="flex items-center justify-center gap-x-10 max-1050:hidden">
            <p className="label-m-700 hidden cursor-pointer text-white hover:underline">
              La communauté
            </p>
            <p
              className="label-m-700 cursor-pointer text-white hover:underline"
              onClick={navigatePrivacy}
            >
              Conditions d'utilisation
            </p>
            <div className="flex items-center gap-x-4">
              <SecondaryButton onClick={navigateToSignIn} label="Connexion" />
              <div
                className="flex h-[33px] cursor-pointer items-center justify-center gap-x-[10px] rounded-[20px] bg-[#6441A5] px-6"
                onClick={handleClick}
              >
                <DiscordIcon22 />
                <p className="button-label text-white">Rejoins le Discord</p>
              </div>
            </div>
          </div>
        </header>

        {isMenuOpen && (
          <div
            className="fixed left-0 top-0 z-50 h-full w-full bg-[#280C31] bg-opacity-100
          "
          >
            <div className="flex h-full w-full flex-col items-start gap-y-16 px-5 py-4">
              <div className="flex w-full flex-col gap-y-8 border-b border-b-[rgba(248,239,251,0.50)] pb-[30px]">
                <div className="flex w-full items-center justify-between">
                  <LogoBeta />
                  <CloseFillIcon onClick={toggleMenu} pointer={true} />
                </div>
                <p
                  className="label-m-700 cursor-pointer text-white hover:underline"
                  onClick={navigatePrivacy}
                >
                  Conditions d'utilisation
                </p>
              </div>
              <div className="flex w-full flex-col justify-center gap-y-4">
                <SecondaryButton
                  onClick={navigateToSignIn}
                  label="Connexion"
                  size="h-10"
                />

                <div
                  className="flex h-[40px] cursor-pointer items-center justify-center gap-x-[10px] rounded-[20px] bg-[#6441A5] px-6 hover:bg-[#774FC2]"
                  onClick={handleClick}
                >
                  <DiscordIcon22 />
                  <p className="button-label text-white">Rejoins le Discord</p>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Graphiques d'arrière-plan */}
        <div className="absolute inset-0 z-0">
          <img
            src={LandingAchievement}
            alt="Achievement icon"
            className="absolute right-0 top-[600px] z-10 h-[200px] min-1050:h-[450px] sm:h-[250px] md:top-[500px] md:h-[300px] lg:h-[350px] xl:h-[400px]"
          />
          <img
            src={LandingGamepad}
            alt="Gamepad icon"
            className="absolute left-0 top-[500px] z-20 w-[175px] min-1050:w-[450px] sm:w-[220px] md:top-[350px] md:w-[300px] lg:w-[350px] xl:w-[400px]"
          />
        </div>

        {/* Section Principale */}
        <main className="z-10 flex w-full flex-col items-center justify-center">
          <img
            src={LandingChat}
            alt="Chat icon"
            className="mb-8 h-[100px] min-1050:h-[215px] sm:h-[125px] md:h-[150px] lg:h-[175px] xl:h-[215px]"
          />
          <div className="flex w-full flex-col items-center justify-center gap-y-20 md:gap-y-12">
            <div className="flex w-full flex-col items-center justify-center gap-y-6">
              <h1 className="text-shadow-custom max-w-[70%] text-center font-russo text-[40px] font-normal leading-tight tracking-[-1.232px] text-white max-400:text-[34px] min-1050:text-[56px]">
                Explore dès maintenant le nouveau réseau social gaming
              </h1>
              <p className="h3-mobile text-center text-white">
                Connecte tes jeux, trouve des joueurs et partage tes gameplays
              </p>
            </div>
            <div
              className="flex h-12 cursor-pointer items-center rounded-[12px] bg-[#B10F2E] px-4 py-[10px] backdrop-blur-[2px] transition-transform duration-300 hover:scale-105"
              onClick={navigateToSignUp}
            >
              <p className="button-label text-white">Crée ton compte gamer</p>
            </div>
          </div>
        </main>

        {/* Flèche Chevron */}
        <div className="flex w-full items-center justify-center pt-[60px]">
          <ChevronLandingPage />
        </div>

        {/* Section de Connexion */}
        <section className="flex w-full flex-col items-center justify-center gap-y-10 pt-[300px]">
          <div className="absolute inset-0 z-0">
            <img
              src={LandingAchievement}
              alt="Achievement icon"
              className="absolute right-0 top-[600px] z-10 h-[200px] min-1050:h-[450px] sm:h-[250px] md:top-[500px] md:h-[300px] lg:h-[350px] xl:h-[400px]"
            />
            <img
              src={LandingGamepad}
              alt="Gamepad icon"
              className="absolute left-0 top-[500px] z-20 w-[175px] min-1050:w-[450px] sm:w-[220px] md:top-[350px] md:w-[300px] lg:w-[350px] xl:w-[400px]"
            />
          </div>
          <div className="flex max-w-[90%] flex-col justify-center gap-y-2 min-1050:max-w-[60%]">
            <p className="paragraph-s-500 text-center text-white">CONNECT</p>
            <div className="flex w-full flex-col items-center justify-center gap-y-4">
              <h2 className="text-shadow-custom text-center font-poppins text-[32px] font-semibold tracking-[-0.704px] text-white">
                Centralise tes jeux et statistiques sur une seule plateforme
              </h2>
              <p className="paragraph-m-400 text-center text-white">
                Avec TalkPlay, synchronise tes jeux de différentes plateformes
                en un seul lieu. Construis ta bibliothèque personnalisée et
                accède facilement à tes statistiques pour les analyser et les
                partager avec tes amis. Rejoins la communauté pour une
                expérience de gaming enrichie et connectée.
              </p>
            </div>
          </div>
          {/* Section des logos des plateformes */}
          <div className="relative flex max-w-[90%] flex-wrap content-center items-center justify-center gap-6">
            <img src={Steam} alt="Steam icon" className="z-10 h-[60px]" />
            <img
              src={RiotGames}
              alt="Riot Games icon"
              className="z-30 h-[60px]"
            />
            <img
              src={Supercell}
              alt="Supercell icon"
              className="z-20 h-[60px]"
            />
            <img
              src={EpicGames}
              alt="Epic Games icon"
              className="z-40 h-[60px]"
            />
            <img
              src={Playstation}
              alt="Playstation icon"
              className="z-50 h-[60px]"
            />
          </div>
          {/* Section Connect Games Images */}
          <div className="relative flex items-center justify-center ">
            <img
              src={ConnectGamesMobile}
              alt="Connect Games Mobile"
              className="z-[100] h-[415px] transition-all duration-300 max-850:absolute max-850:left-1/2 max-850:top-[70%] max-850:block max-850:-translate-x-1/2 max-850:-translate-y-1/2 max-mobile:h-[315px]"
            />
            <img
              src={ConnectGamesDesktop}
              alt="Connect games Desktop"
              className="z-40 h-[300px] overflow-hidden object-cover max-400:hidden max-500:inset-0 sm:h-[300px] md:h-[350px] lg:h-[400px] xl:h-[450px]"
            />
            <img
              src={ConnectGamesDesktop2}
              alt="Connect games Desktop"
              className="z-40 h-[300px] w-full overflow-hidden object-cover min-400:hidden"
            />
          </div>
        </section>

        {/* Section Partage tes gameplays */}
        <section className="relative flex w-full flex-col items-center justify-center gap-y-10 pt-[300px]">
          {/* Image de fond pour la section des gameplays */}
          <img
            src={GameplayBG}
            alt="Background Gameplay"
            className="absolute left-0 top-0 z-0 aspect-square w-full object-cover max-650:mt-[1000px]"
          />
          <div className="relative z-10 flex flex-col items-center justify-center gap-y-8">
            <div className="flex max-w-[90%] flex-col justify-center gap-y-2 min-1050:max-w-[55%]">
              <p className="paragraph-s-500 text-center text-white">PLAY</p>
              <div className="flex w-full flex-col items-center justify-center gap-y-4">
                <h2 className="text-shadow-custom text-center font-poppins text-[32px] font-semibold tracking-[-0.704px] text-white">
                  Partage tes exploits et construis ta bibliothèque de gameplays
                  sur TalkPlay
                </h2>
                <p className="paragraph-m-400 text-center text-white">
                  Partage tes meilleures sessions de jeu sur TalkPlay ! Découvre
                  et compare les kills et combos de la communauté, et conserve
                  tous tes moments forts dans ta bibliothèque. Connecte-toi et
                  inspire-toi des meilleurs !
                </p>
              </div>
            </div>
            <div
              className="flex h-12 cursor-pointer items-center rounded-[12px] bg-[#B10F2E] px-4 py-[10px] backdrop-blur-[2px] transition-transform duration-300 hover:scale-105"
              onClick={navigateToSignUp}
            >
              <p className="button-label text-white">Partage tes gameplays</p>
            </div>

            {/* Section des trois images */}
            <div className="flex flex-col items-center justify-center space-y-4 pt-10 md:flex-row md:space-x-4 md:space-y-0">
              {carouselImages.map((img, index) => (
                <div
                  key={img}
                  onClick={() => handleCarouselClick(img)}
                  className={`relative transform cursor-pointer transition-transform duration-700 ${
                    index === 1
                      ? "z-20 h-40 w-[100%] scale-110 shadow-lg md:h-80 md:w-1/3"
                      : "z-10 h-[120px] w-[100%] scale-100 opacity-80 md:h-60 md:w-1/4"
                  }`}
                >
                  <img
                    src={img}
                    alt={`Gameplay ${index + 1}`}
                    className="h-full w-full rounded-lg object-cover"
                  />
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className="z-10 flex w-screen flex-col items-center justify-center gap-y-10 pt-[300px]">
          <div className="flex flex-col items-center justify-center gap-y-8">
            <div className="flex max-w-[90%] flex-col justify-center gap-y-2 min-1050:max-w-[55%]">
              <p className="paragraph-s-500 text-center text-white">FIND</p>
              <div className="flex items-center justify-center opacity-80">
                <SecondaryButton
                  label="PROCHAINEMENT"
                  size="label-s-600 w-[150px] !cursor-default"
                />
              </div>

              <div className="flex w-full flex-col items-center justify-center gap-y-4">
                <h2 className="text-shadow-custom text-center font-poppins text-[32px] font-semibold tracking-[-0.704px] text-white">
                  Retrouve tes amis in-game et tes gamers favoris, même s’ils ne
                  sont pas encore sur TalkPlay
                </h2>
                <p className="paragraph-m-400 text-center text-white">
                  Re-crée ta communauté et compare tes statistiques avec celles
                  des autres gamers de tes jeux favoris. Nous ajoutons
                  régulièrement de nouveaux jeux et donc de nouvelles
                  communautés qui viennent enrichir l’expérience TalkPlay.
                </p>
              </div>
            </div>
            <div className="jsutify-center flex w-screen flex-col items-center gap-y-8 overflow-hidden">
              <h3 className="h3-mobile">Jeux ajoutés</h3>
              <div className="flex flex-nowrap gap-x-10 overflow-auto scroll-smooth md:flex-wrap md:overflow-hidden">
                <GameCoverColumn
                  banner={CoverCoC}
                  size="w-[175px] h-[262px]"
                  isClickable={false}
                />
                <GameCoverColumn
                  banner={CoverFortnite}
                  size="w-[175px] h-[262px]"
                  isClickable={false}
                />
                <GameCoverColumn
                  banner={CoverLoL}
                  size="w-[175px] h-[262px]"
                  isClickable={false}
                />
                <GameCoverColumn
                  banner={CoverValorant}
                  size="w-[175px] h-[262px]"
                  isClickable={false}
                />
                <GameCoverColumn
                  banner={CoverRL}
                  size="w-[175px] h-[262px]"
                  isClickable={false}
                />
              </div>
            </div>
          </div>
        </section>

        <section className="flex w-full flex-col items-center justify-center gap-y-10 pt-[300px]">
          <div className="flex flex-col items-center justify-center gap-y-8">
            <div className="flex max-w-[90%] flex-col justify-center gap-y-2 min-1050:max-w-[55%]">
              <p className="paragraph-s-500 text-center text-white">TALK</p>
              <div className="flex items-center justify-center opacity-80">
                <SecondaryButton
                  label="PROCHAINEMENT"
                  size="label-s-600 w-[150px] !cursor-default"
                />
              </div>

              <div className="flex w-full flex-col items-center justify-center gap-y-4">
                <h2 className="text-shadow-custom text-center font-poppins text-[32px] font-semibold tracking-[-0.704px] text-white">
                  Tes groupes in-game sont déjà des groupes de discussion sur
                  TalkPlay
                </h2>
                <p className="paragraph-m-400 text-center text-white">
                  En connectant tes comptes de jeux à TalkPlay, nous intégrons
                  automatiquement tes groupes in-game dans des espaces de
                  discussion dédiés. Échange facilement avec tes amis et
                  interagis avec ta communauté.
                </p>
              </div>
            </div>
            <div className="relative flex w-full items-center justify-center">
              <div
                className="relative z-40 flex w-[60%] flex-col items-center justify-center overflow-hidden rounded-lg object-cover px-5 backdrop-blur-[2px] max-400:hidden max-850:w-[100%]"
                style={{
                  aspectRatio: "8 / 4",
                  background: `linear-gradient(180deg, rgba(53, 16, 65, 0.00) 0%, rgba(53, 16, 65, 0.00) 75.8%, rgba(53, 16, 65, 0.80) 89.46%, #280C31 100%), url(${COCScreen}) lightgray -40.192px 0px / 128.686% 100% no-repeat`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              ></div>
              <div
                className="aspect-ratio-2/1 z-40 w-full overflow-hidden rounded-lg bg-left backdrop-blur-sm max-400:bg-right min-400:hidden"
                style={{
                  aspectRatio: "8 / 4",
                  background: `linear-gradient(180deg, rgba(53, 16, 65, 0.00) 0%, rgba(53, 16, 65, 0.00) 75.8%, rgba(53, 16, 65, 0.80) 89.46%, #280C31 100%), url(${COCScreen}) lightgray -40.192px 0px / 128.686% 100% no-repeat`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              ></div>
              <img
                src={ClanDiscussion}
                alt="Connect Games Mobile"
                className="z-[100] h-[615px] transition-all duration-300 max-500:max-h-[350px] max-850:absolute max-850:left-[70%] max-850:top-[70%] max-850:block max-850:-translate-x-1/2 max-850:-translate-y-1/2 max-mobile:h-[455px]"
              />
            </div>
          </div>
        </section>
        <section className="flex w-full items-center justify-center pt-[300px] max-1050:flex-col">
          <div className="flex w-[30%] flex-col items-start gap-y-6 max-1050:w-[80%] max-1050:items-center max-1050:justify-center">
            <div className="flex flex-col gap-y-2 max-1050:items-center">
              <p className="paragraph-s-500 text-left text-white">
                JOIN THE COMMUNITY
              </p>
              <h2 className="text-shadow-custom text-left font-poppins text-[32px] font-semibold tracking-[-0.704px] text-white max-1050:text-center">
                Toute ton expérience de gaming au même endroit avec TalkPlay
              </h2>
            </div>
            <div
              className="flex h-12 w-full max-w-[380px] cursor-pointer items-center justify-center rounded-[12px] bg-[#B10F2E] px-4 py-[10px] backdrop-blur-[2px] transition-transform duration-300 hover:scale-105"
              onClick={navigateToSignUp}
            >
              <p className="button-label text-white">REJOINS LA BETA</p>
            </div>
          </div>
          <img
            src={BottomBanner}
            alt="Connect Games Mobile"
            className="z-[100] h-full w-[50%] max-1050:w-[100%]"
          />
        </section>

        {/* Footer */}
        <footer className="z-[100] flex w-full flex-col bg-[#0C0C0C] px-[88px] pb-[58px] pt-12 max-1050:px-5">
          <div className="flex w-full justify-between max-650:gap-y-7 max-1050:flex-col max-1050:gap-y-10">
            <div className="flex w-[50%] gap-x-20 max-650:flex-col max-650:gap-y-7 max-1050:w-full min-1050:justify-between">
              <div className="flex flex-col gap-y-6 pb-2">
                <div className="flex flex-col items-start gap-y-1">
                  <h1 className="h1-mobile">TalkPlay</h1>
                  <p className="paragraph-m-400">
                    Connect games,
                    <br /> Find any player, Share gameplay.
                  </p>
                </div>
                <div
                  className="flex w-[154px] cursor-pointer items-center justify-center rounded-[8px] bg-white px-4 py-3 transition-transform duration-300 hover:scale-105"
                  onClick={navigateToSignUp}
                >
                  <p className="button-label whitespace-nowrap text-black">
                    Rejoindre la Beta
                  </p>
                </div>
              </div>
              <div className="flex flex-col gap-y-4">
                <p
                  className="link-s cursor-pointer hover:font-bold"
                  onClick={navigateHome}
                >
                  Page d’accueil
                </p>
                <p
                  className="link-s cursor-pointer hover:font-bold"
                  onClick={navigatePrivacy}
                >
                  Condition d’utilisation
                </p>
                <p
                  className="link-s cursor-pointer hover:font-bold"
                  onClick={navigateTerms}
                >
                  Termes
                </p>
                <p
                  className="link-s cursor-pointer hover:font-bold"
                  onClick={navigateToSignUp}
                >
                  Inscription
                </p>
              </div>
            </div>
            <div className="w-[30%] max-1050:w-full">
              <p className="paragraph-m-400">
                TalkPlay est une plateforme communautaire qui centralise
                l'expérience de gaming. Retrouve tes jeux et discussions en un
                seul lieu, crée ta bibliothèque pour suivre et partager des
                statistiques et gameplays. Continuellement enrichie de nouveaux
                jeux, TalkPlay dynamise et unit la communauté des gamers.
              </p>
            </div>
            <div className="min-1050:hidden">
              <LinkedInIcon />
            </div>
          </div>
          <div className="max-1050:hidden">
            <LinkedInIcon />
          </div>
        </footer>
      </div>
    </div>
  );
};

import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getGameplaysUserGames } from "../../helpers/fetchUser";
import {
  ChevronIcon,
  MediaThumbnail,
  SubtitleNavigation,
} from "../../components";
import { GameWithGameplays } from "../../types/Gameplay";
import { UserLayout } from "./UserLayout";

const postButtons = [
  { title: "Date", path: "", exact: true },
  { title: "Jeux", path: "jeux", exact: false },
];

export const UserGameplayJeuxPage = () => {
  const { username } = useParams();
  const navigate = useNavigate();
  const [gamesWithGameplays, setGamesWithGameplays] = useState<
    GameWithGameplays[]
  >([]);
  const [selectedFilter, setSelectedFilter] = useState<string>("Jeux");

  useEffect(() => {
    const fetchGameplays = async () => {
      if (username) {
        try {
          const data = await getGameplaysUserGames(username);
          setGamesWithGameplays(data);
        } catch (error) {
          console.error(
            "Erreur lors de la récupération des jeux avec gameplays",
            error,
          );
        }
      }
    };

    fetchGameplays();
  }, [username]);

  const handleNavigation = (path: string) => {
    setSelectedFilter(path ? "Jeux" : "Date");
    if (path === "jeux") {
      navigate(`/user/${username}/plays/jeux`);
    } else {
      navigate(`/user/${username}/plays`);
    }
  };

  const handleGameClick = (gameId: string) => {
    navigate(`/user/${username}/plays/jeux/${gameId}`);
  };

  return (
    <UserLayout>
      <div className="flex h-full w-full flex-col items-center gap-y-4 px-5">
        <div className="flex items-start gap-x-2 max-mobile:pt-2">
          <SubtitleNavigation
            buttons={postButtons.map((button) => ({
              ...button,
              path: `/user/${username}/plays${button.path ? `/${button.path}` : ""}`,
              onClick: () => handleNavigation(button.path),
            }))}
            secondary={true}
            padding="p-0"
            activeButton={selectedFilter}
          />
        </div>
        <div className="flex w-full flex-col items-center gap-y-4 pb-8 max-tablet:max-w-[610px]">
          <div className="grid w-full grid-cols-1 gap-4 sm:grid-cols-2 max-mobile:pb-20 lg:grid-cols-3">
            {gamesWithGameplays.length > 0 ? (
              gamesWithGameplays.map((game) => (
                <div
                  key={game.game.id}
                  className="flex cursor-pointer flex-col items-start gap-y-2 rounded-[6px] bg-[rgba(245,235,255,0.10)] p-4"
                  onClick={() => handleGameClick(game.game.id)}
                >
                  <div className="flex w-full items-center justify-between">
                    <div className="flex items-center gap-x-2">
                      <MediaThumbnail banner={game.game.logo} size="s" />
                      <div className="flex flex-col">
                        <h3 className="h3-mobile">{game.game.name}</h3>
                        <p className="paragraph-s-400">
                          {game.gameplayCount}{" "}
                          {game.gameplayCount === 1 ? "Play" : "Plays"}
                        </p>
                      </div>
                    </div>
                    <ChevronIcon orientation="right" />
                  </div>
                </div>
              ))
            ) : (
              <p className="paragraph-s-400 text-center">
                Vous n'avez pas encore de play.
              </p>
            )}
          </div>
        </div>
      </div>
    </UserLayout>
  );
};

import { useNavigate } from "react-router-dom";
import logosmallTP from "../../assets/logoTP.png";
import logoTP from "../../assets/LogoTPTextBlanc.png";
import logoblackTP from "../../assets/LogoTPTextNoir.png";

interface LogoSmallBetaProps {
  clickable?: boolean;
}

export const LogoSmallBeta: React.FC<LogoSmallBetaProps> = ({
  clickable = true,
}) => {
  const navigate = useNavigate();

  const navigateHome = () => {
    if (clickable) {
      navigate(`/home`);
    }
  };

  return (
    <div
      className={`flex items-center gap-x-1 ${clickable ? "cursor-pointer" : ""}`}
      onClick={navigateHome}
    >
      <img src={logosmallTP} alt="TalkPlay" className="h-[30px]" />
      <div className="flex items-center justify-center rounded-full border border-[rgba(255,255,255,0.2)] bg-[rgba(248,239,251,0.10)] px-2 shadow-[inset_-1px_1px_2px_rgba(0,0,0,0.25)] backdrop-blur-[2px]">
        <p className="beta">BETA</p>
      </div>
    </div>
  );
};

interface LogoBetaProps {
  textColor?: "white" | "black";
  clickable?: boolean;
}

export const LogoBeta: React.FC<LogoBetaProps> = ({
  textColor = "white",
  clickable = true,
}) => {
  const navigate = useNavigate();

  const navigateHome = () => {
    if (clickable) {
      navigate(`/home`);
    }
  };

  const logoSrc = textColor === "white" ? logoTP : logoblackTP;

  return (
    <div
      className={`relative flex items-center gap-x-1 ${clickable ? "cursor-pointer" : ""}`}
      onClick={navigateHome}
    >
      <img src={logoSrc} alt="TalkPlay" className="h-[auto] w-[150px]" />
      <div
        className={`absolute right-[-8px] top-[-2px] flex items-center justify-center rounded-full border border-[rgba(255,255,255,0.2)] ${textColor === "white" ? "bg-[rgba(248,239,251,0.10)]" : "bg-[rgb(60,36,70)]"} px-2 shadow-[inset_-1px_1px_2px_rgba(0,0,0,0.25)] backdrop-blur-[2px]`}
      >
        <p className="beta leading-3">BETA</p>
      </div>
    </div>
  );
};

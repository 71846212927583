import {
  BadgeConnected,
  BadgeDisconnected,
  BadgePlatformConnected,
  BadgePlatformNotConnected,
} from "../../components";

interface PlatformCardProps {
  logo: string;
  name: string;
  gameCount: number;
  isConnected: boolean;
  onClick?: () => void;
}

export const PlatformCard: React.FC<PlatformCardProps> = ({
  logo,
  name,
  gameCount,
  isConnected,
  onClick,
}) => {
  return (
    <div
      className="relative flex h-[100px] w-[100px] cursor-pointer flex-col items-center justify-center rounded-lg "
      onClick={onClick}
    >
      <div className="absolute right-[-8px] top-[-8px]">
        {isConnected ? (
          <BadgePlatformConnected />
        ) : (
          <BadgePlatformNotConnected />
        )}
      </div>
      <img
        src={logo}
        alt={name}
        className="h-full w-full rounded-lg border border-[rgba(255,255,255,0.50)] object-contain"
      />
      <div className="absolute bottom-1 right-1 flex h-[15px] w-[15px] items-center justify-center rounded-full border border-solid border-[#F8EFFB] bg-[rgba(255,255,255,0.40)] px-1 py-1 shadow-[0px_4px_4px_rgba(0,0,0,0.25)] backdrop-blur-[2px]">
        <p className="text-center font-poppins text-[10px] font-[700] text-[#F8EFFB]">
          {gameCount}
        </p>
      </div>
    </div>
  );
};

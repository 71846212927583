export const DiscordIcon = () => (
  <svg
    width="24"
    height="28"
    viewBox="0 0 24 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9223 13.0625C15.9223 13.9012 15.3051 14.5887 14.5234 14.5887C13.7554 14.5887 13.1246 13.9012 13.1246 13.0625C13.1246 12.2237 13.7417 11.5362 14.5234 11.5362C15.3051 11.5362 15.9223 12.2237 15.9223 13.0625ZM9.51771 11.5362C8.736 11.5362 8.11886 12.2237 8.11886 13.0625C8.11886 13.9012 8.74971 14.5887 9.51771 14.5887C10.2994 14.5887 10.9166 13.9012 10.9166 13.0625C10.9303 12.2237 10.2994 11.5362 9.51771 11.5362ZM24 2.8325V27.5C20.545 24.4388 21.6499 25.4521 17.6366 21.7112L18.3634 24.255H2.81143C1.26171 24.255 0 22.99 0 21.4225V2.8325C0 1.265 1.26171 0 2.81143 0H21.1886C22.7383 0 24 1.265 24 2.8325ZM20.0914 15.8675C20.0914 11.44 18.1166 7.85125 18.1166 7.85125C16.1417 6.36625 14.2629 6.4075 14.2629 6.4075L14.0709 6.6275C16.4023 7.3425 17.4857 8.37375 17.4857 8.37375C14.228 6.58362 10.4012 6.58329 7.24114 7.975C6.73371 8.20875 6.432 8.37375 6.432 8.37375C6.432 8.37375 7.57029 7.2875 10.0389 6.5725L9.90171 6.4075C9.90171 6.4075 8.02286 6.36625 6.048 7.85125C6.048 7.85125 4.07314 11.44 4.07314 15.8675C4.07314 15.8675 5.22514 17.8612 8.256 17.9575C8.256 17.9575 8.76343 17.3387 9.17486 16.8162C7.43314 16.2937 6.77486 15.1937 6.77486 15.1937C6.97661 15.3353 7.30929 15.5189 7.33714 15.5375C9.65196 16.8372 12.9401 17.263 15.8949 16.0187C16.3749 15.84 16.9097 15.5787 17.472 15.2075C17.472 15.2075 16.7863 16.335 14.9897 16.8437C15.4011 17.3662 15.8949 17.9575 15.8949 17.9575C18.9257 17.8612 20.0914 15.8675 20.0914 15.8675Z"
      fill="#F8EFFB"
    />
  </svg>
);
export const DiscordIcon22 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
  >
    <path
      d="M14.146 10.45C14.146 11.121 13.651 11.671 13.024 11.671C12.408 11.671 11.902 11.121 11.902 10.45C11.902 9.779 12.397 9.229 13.024 9.229C13.651 9.229 14.146 9.779 14.146 10.45ZM9.009 9.229C8.382 9.229 7.887 9.779 7.887 10.45C7.887 11.121 8.393 11.671 9.009 11.671C9.636 11.671 10.131 11.121 10.131 10.45C10.142 9.779 9.636 9.229 9.009 9.229ZM20.625 2.266V22C17.8538 19.551 18.74 20.3617 15.521 17.369L16.104 19.404H3.63C2.387 19.404 1.375 18.392 1.375 17.138V2.266C1.375 1.012 2.387 0 3.63 0H18.37C19.613 0 20.625 1.012 20.625 2.266ZM17.49 12.694C17.49 9.152 15.906 6.281 15.906 6.281C14.322 5.093 12.815 5.126 12.815 5.126L12.661 5.302C14.531 5.874 15.4 6.699 15.4 6.699C12.787 5.26689 9.71764 5.26664 7.183 6.38C6.776 6.567 6.534 6.699 6.534 6.699C6.534 6.699 7.447 5.83 9.427 5.258L9.317 5.126C9.317 5.126 7.81 5.093 6.226 6.281C6.226 6.281 4.642 9.152 4.642 12.694C4.642 12.694 5.566 14.289 7.997 14.366C7.997 14.366 8.404 13.871 8.734 13.453C7.337 13.035 6.809 12.155 6.809 12.155C6.97082 12.2683 7.23766 12.4151 7.26 12.43C9.11668 13.4698 11.754 13.8104 14.124 12.815C14.509 12.672 14.938 12.463 15.389 12.166C15.389 12.166 14.839 13.068 13.398 13.475C13.728 13.893 14.124 14.366 14.124 14.366C16.555 14.289 17.49 12.694 17.49 12.694Z"
      fill="white"
    />
  </svg>
);

export const TwitchIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.0022 4.85156H16.9978V9.99375H18.997L19.0022 4.85156ZM13.5019 4.82812H11.5028V9.975H13.5019V4.82812ZM4.99784 0L0 4.28438V19.7156H5.99741V24L10.9953 19.7156H14.9987L24 12V0H4.99784ZM22.0009 11.1469L18.0026 14.5734H13.9991L10.4981 17.5734V14.5734H5.99741V1.71562H22.0009V11.1469Z"
      fill="white"
    />
  </svg>
);

export const InstagramIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0027 5.8467C8.59743 5.8467 5.85075 8.594 5.85075 12C5.85075 15.406 8.59743 18.1533 12.0027 18.1533C15.4079 18.1533 18.1546 15.406 18.1546 12C18.1546 8.594 15.4079 5.8467 12.0027 5.8467ZM12.0027 16.0004C9.80212 16.0004 8.00312 14.2064 8.00312 12C8.00312 9.7936 9.79677 7.99955 12.0027 7.99955C14.2086 7.99955 16.0022 9.7936 16.0022 12C16.0022 14.2064 14.2032 16.0004 12.0027 16.0004ZM19.8412 5.595C19.8412 6.39295 19.1987 7.03024 18.4062 7.03024C17.6085 7.03024 16.9713 6.38759 16.9713 5.595C16.9713 4.80241 17.6138 4.15977 18.4062 4.15977C19.1987 4.15977 19.8412 4.80241 19.8412 5.595ZM23.9157 7.05166C23.8247 5.12909 23.3856 3.42608 21.9775 2.02298C20.5747 0.619882 18.8721 0.180743 16.9499 0.0843468C14.9689 -0.0281156 9.03112 -0.0281156 7.05008 0.0843468C5.1333 0.175388 3.43068 0.614526 2.02253 2.01763C0.614389 3.42073 0.180703 5.12373 0.0843279 7.0463C-0.0281093 9.02778 -0.0281093 14.9669 0.0843279 16.9483C0.175349 18.8709 0.614389 20.5739 2.02253 21.977C3.43068 23.3801 5.12794 23.8193 7.05008 23.9157C9.03112 24.0281 14.9689 24.0281 16.9499 23.9157C18.8721 23.8246 20.5747 23.3855 21.9775 21.977C23.3803 20.5739 23.8193 18.8709 23.9157 16.9483C24.0281 14.9669 24.0281 9.03314 23.9157 7.05166ZM21.3564 19.0744C20.9388 20.1241 20.1303 20.9327 19.0755 21.3558C17.496 21.9824 13.7481 21.8378 12.0027 21.8378C10.2572 21.8378 6.50396 21.977 4.92984 21.3558C3.88042 20.9381 3.07195 20.1294 2.64897 19.0744C2.02253 17.4946 2.16709 13.7458 2.16709 12C2.16709 10.2542 2.02789 6.50006 2.64897 4.92558C3.06659 3.87593 3.87507 3.06728 4.92984 2.6442C6.50931 2.01763 10.2572 2.16222 12.0027 2.16222C13.7481 2.16222 17.5014 2.02298 19.0755 2.6442C20.1249 3.06192 20.9334 3.87058 21.3564 4.92558C21.9828 6.50541 21.8383 10.2542 21.8383 12C21.8383 13.7458 21.9828 17.4999 21.3564 19.0744Z"
      fill="white"
    />
  </svg>
);

export const TwitterIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.8996 0H22.5815L14.5398 10.1654L24 24H16.5945L10.7901 15.6115L4.15645 24H0.469361L9.0691 13.125L0 0H7.59322L12.8344 7.66731L18.8996 0ZM17.6063 21.5654H19.6454L6.4824 2.30769H4.29205L17.6063 21.5654Z"
      fill="white"
    />
  </svg>
);

export const TiktokIcon = () => (
  <svg
    width="22"
    height="24"
    viewBox="0 0 22 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5255 0.0199997C12.8355 0 14.1354 0.00999987 15.4354 0C15.5154 1.52998 16.0654 3.08996 17.1854 4.16995C18.3054 5.27993 19.8854 5.78993 21.4253 5.95993V9.98987C19.9854 9.93987 18.5354 9.63988 17.2254 9.01989C16.6554 8.75989 16.1254 8.42989 15.6054 8.0899C15.5954 11.0099 15.6154 13.9298 15.5854 16.8398C15.5054 18.2398 15.0454 19.6298 14.2354 20.7797C12.9255 22.6997 10.6555 23.9497 8.32551 23.9897C6.89553 24.0697 5.46555 23.6797 4.24556 22.9597C2.22559 21.7697 0.805606 19.5898 0.595608 17.2498C0.575609 16.7498 0.565609 16.2498 0.585609 15.7598C0.765606 13.8598 1.70559 12.0398 3.16558 10.7999C4.82556 9.35988 7.14552 8.66989 9.3155 9.07989C9.3355 10.5599 9.2755 12.0398 9.2755 13.5198C8.28551 13.1998 7.12553 13.2898 6.25554 13.8898C5.62555 14.2998 5.14555 14.9298 4.89555 15.6398C4.68556 16.1498 4.74556 16.7098 4.75556 17.2498C4.99555 18.8898 6.57553 20.2697 8.25551 20.1197C9.3755 20.1097 10.4455 19.4598 11.0255 18.5098C11.2155 18.1798 11.4255 17.8398 11.4355 17.4498C11.5355 15.6598 11.4955 13.8798 11.5055 12.0898C11.5155 8.0599 11.4955 4.03995 11.5255 0.0199997Z"
      fill="white"
    />
  </svg>
);

export const YoutubeIcon = () => {
  return (
    <svg
      width="24"
      height="17"
      viewBox="0 0 24 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.4998 2.58275C23.2249 1.5677 22.4117 0.766912 21.377 0.492486C19.5062 3.2305e-07 12 0 12 0C12 0 4.49762 3.2305e-07 2.62297 0.492486C1.59211 0.763163 0.778903 1.56395 0.500185 2.58275C-5.48191e-08 4.42492 0 8.27086 0 8.27086C0 8.27086 -5.48191e-08 12.1168 0.500185 13.959C0.775086 14.974 1.58829 15.7748 2.62297 16.0493C4.49762 16.5418 12 16.5418 12 16.5418C12 16.5418 19.5062 16.5418 21.377 16.0493C22.4079 15.7786 23.2211 14.9778 23.4998 13.959C24 12.1168 24 8.27086 24 8.27086C24 8.27086 24 4.42492 23.4998 2.58275Z"
        fill="white"
      />
      <path
        d="M9.60229 11.8161L15.8371 8.27086L9.60229 4.72568V11.8161Z"
        fill="black"
      />
    </svg>
  );
};

export const SteamIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M12 0C5.38338 0 0 5.38338 0 12C0 18.6166 5.38338 24 12 24C18.6166 24 24 18.6166 24 12C24 5.38338 18.6166 0 12 0ZM12 1.84615C17.5985 1.84615 22.1538 6.40154 22.1538 12C22.1538 17.5985 17.5985 22.1538 12 22.1538C7.28446 22.1538 3.32175 18.9177 2.1851 14.5529L5.56911 16.0024C5.72487 17.3842 6.88422 18.4615 8.30769 18.4615C9.83723 18.4615 11.0769 17.2218 11.0769 15.6923C11.0769 15.6628 11.0688 15.6344 11.0679 15.6058L14.9243 12.9087C16.8904 12.8256 18.4615 11.2172 18.4615 9.23077C18.4615 7.19169 16.8083 5.53846 14.7692 5.53846C12.7828 5.53846 11.1753 7.10957 11.0931 9.07572L8.39423 12.9321C8.36469 12.9312 8.33723 12.9231 8.30769 12.9231C7.69541 12.9231 7.13454 13.1276 6.67608 13.4639L1.8768 11.4069C2.18665 6.08488 6.60176 1.84615 12 1.84615ZM14.7692 6.46154C16.2988 6.46154 17.5385 7.70123 17.5385 9.23077C17.5385 10.7603 16.2988 12 14.7692 12C13.2397 12 12 10.7603 12 9.23077C12 7.70123 13.2397 6.46154 14.7692 6.46154ZM14.7692 7.38462C14.2796 7.38462 13.81 7.57912 13.4638 7.92534C13.1176 8.27156 12.9231 8.74114 12.9231 9.23077C12.9231 9.7204 13.1176 10.19 13.4638 10.5362C13.81 10.8824 14.2796 11.0769 14.7692 11.0769C15.2589 11.0769 15.7284 10.8824 16.0747 10.5362C16.4209 10.19 16.6154 9.7204 16.6154 9.23077C16.6154 8.74114 16.4209 8.27156 16.0747 7.92534C15.7284 7.57912 15.2589 7.38462 14.7692 7.38462ZM8.30769 13.8462C9.32769 13.8462 10.1538 14.6723 10.1538 15.6923C10.1538 16.7123 9.32769 17.5385 8.30769 17.5385C7.56112 17.5385 6.92195 17.094 6.63101 16.4567L7.35397 16.7668C7.5312 16.8434 7.71659 16.8804 7.89844 16.8804C8.43567 16.8804 8.94785 16.5655 9.17308 16.0403C9.474 15.3378 9.1481 14.5239 8.44471 14.223L7.77224 13.9345C7.94283 13.8825 8.12004 13.8462 8.30769 13.8462Z"
      fill="white"
    />
  </svg>
);

export const PlayStationIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="20"
    viewBox="0 0 24 20"
    fill="none"
  >
    <path
      d="M8.98355 0.596453V18.1431L12.8977 19.4039V4.68613C12.8977 3.99389 13.203 3.5334 13.695 3.69315C14.328 3.87689 14.4532 4.50988 14.4532 5.20137V11.076C16.8952 12.2677 18.8159 11.0723 18.8159 7.92382C18.8159 4.68613 17.6909 3.24766 14.379 2.09568C13.0702 1.64568 10.6478 0.907698 8.98805 0.595703L8.98355 0.596453ZM13.6402 16.8382L19.9371 14.5605C20.6511 14.3032 20.7613 13.9365 20.1831 13.7445C19.5974 13.5532 18.5459 13.6035 17.8229 13.8652L13.62 15.3652V12.978L13.8577 12.8962C13.8577 12.8962 15.0607 12.4755 16.7714 12.279C18.4671 12.0997 20.5573 12.3097 22.2096 12.939C24.0575 13.5412 24.2525 14.412 23.7838 15.0142C23.3188 15.6112 22.1631 16.0492 22.1631 16.0492L13.62 19.1541V16.8569L13.6402 16.8382ZM1.80843 16.6004C-0.0942844 16.0537 -0.406279 14.9324 0.456206 14.2755C1.25719 13.6935 2.61242 13.2255 2.61242 13.2255L8.22906 11.2133V13.5255L4.20614 14.9984C3.4989 15.2684 3.3819 15.6314 3.96839 15.8227C4.55413 16.0184 5.60411 15.9757 6.30835 15.7057L8.24556 14.9984V17.0722C8.12857 17.0999 7.99132 17.1149 7.85482 17.1464C5.91761 17.4756 3.86264 17.3422 1.81968 16.6664L1.80843 16.6004Z"
      fill="white"
    />
  </svg>
);

export const LinkedInIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="31"
    height="31"
    viewBox="0 0 31 31"
    fill="none"
  >
    <path
      d="M26.4107 26.4172H21.8201V19.2239C21.8201 17.5085 21.7852 15.3011 19.428 15.3011C17.0345 15.3011 16.669 17.1675 16.669 19.0973V26.4172H12.0784V11.625H16.4881V13.6413H16.5475C17.1637 12.4788 18.662 11.2517 20.9005 11.2517C25.5518 11.2517 26.412 14.313 26.412 18.2978L26.4107 26.4172ZM6.89363 9.60096C5.41596 9.60096 4.22892 8.40487 4.22892 6.93367C4.22892 5.46375 5.41725 4.26896 6.89363 4.26896C8.36613 4.26896 9.55963 5.46375 9.55963 6.93367C9.55963 8.40487 8.36483 9.60096 6.89363 9.60096ZM9.19537 26.4172H4.59187V11.625H9.19537V26.4172ZM28.7073 0H2.28754C1.023 0 0 0.99975 0 2.23329V28.7667C0 30.0015 1.023 31 2.28754 31H28.7034C29.9667 31 31 30.0015 31 28.7667V2.23329C31 0.99975 29.9667 0 28.7034 0L28.7073 0Z"
      fill="white"
    />
  </svg>
);

import config from "../config.json";

export const server =
  config.environment === "DEV"
    ? "http://localhost"
    : config.environment === "PPROD"
      ? "https://pprod.talkplay.fr"
      : "https://talkplay.fr";

export const serverPort = config.environment === "DEV" ? ":3333" : "";
export const socketPort = config.environment === "DEV" ? ":3000" : "";
export const steamPort = config.environment === "DEV" ? ":3001" : "";
export const steamAuthUrl = server + steamPort + "/auth/steam";
export const socketUrl = server + socketPort;
export const apiUrl = server + serverPort + "/api/";
export const fetchOnePostUrl = apiUrl + "post/";
export const fetchUserByUsernameUrl = apiUrl + "user/findByUsername/";
export const fetchUserByTokenUrl = apiUrl + "user/findByToken/";
export const updateUserUrl = apiUrl + "user/";
export const loginUrl = apiUrl + "login/";
export const registerUrl = apiUrl + "register/";
export const fetchGameByIdUrl = apiUrl + "game/";
export const fetchAllGamesUrl = apiUrl + "games/";
export const fetchAllLauncherUrl = apiUrl + "launcher/";
export const fetchStudioByIdUrl = apiUrl + "studio/";
export const fetchAllStudiosUrl = apiUrl + "studios/";
export const fetchUsernameExistUrl = apiUrl + "existUsername/";
export const preRegisterUrl = apiUrl + "preregister/";
export const createPostUrl = apiUrl + "post/";
export const deletePostUrl = apiUrl + "post/";
export const getTimelineUrl = apiUrl + "posts/getTimeline/";
export const addSupercellGameUrl = apiUrl + "game/addgame";
export const getPlayerAccountUrl = apiUrl + "game/getPlayerAccount/";
export const likePostUrl = apiUrl + "post/like/";
export const unLikePostUrl = apiUrl + "post/unLike/";
export const repostPostUrl = apiUrl + "post/repost/";
export const unRepostPostUrl = apiUrl + "post/unRepost/";
export const followGameUrl = apiUrl + "game/followGame";
export const unFollowGameUrl = apiUrl + "game/unfollowGame";
export const getFriendsUrl = apiUrl + "user/getFriends/";
export const getFriendsRequestsUrl = apiUrl + "users/getFriendsRequests/";
export const followUserUrl = apiUrl + "user/followUser";
export const addFriendUrl = apiUrl + "user/addFriend";
export const unFollowUserUrl = apiUrl + "user/unfollowUser";
export const removeFriendUrl = apiUrl + "user/removeFriend";
export const hideUserUrl = apiUrl + "user/hideUser";
export const unHideUserUrl = apiUrl + "user/unHideUser";
export const getUserGamesUrl = apiUrl + "games/getGames/";
export const createGroupUrl = apiUrl + "group";
export const getPlayerStatsUrl = apiUrl + "game/getPlayerStats/";
export const updateGameUrl = apiUrl + "game/";
export const getUserPostsUrl = apiUrl + "posts/getUserPosts/";
export const createDiscussionUrl = apiUrl + "discussion";
export const fetchAllPlatformsUrl = apiUrl + "platforms/";
export const fetchGamesStudiosUrl = apiUrl + "studio/";
export const deleteDiscussionUrl = apiUrl + "discussion/";
export const deleteGroupUrl = apiUrl + "group/";
export const fetchSearchUrl = apiUrl + "users/search/";
export const linkLauncherUrl = apiUrl + "launcher/link";
export const createGameplayUrl = apiUrl + "gameplay/";
export const fetchUserGameplaysUrl = apiUrl + "gameplays/getUserGameplays/";
export const fetchGameplayTimelineUrl = apiUrl + "gameplays/getTimeline/";
export const commentGameplayUrl = apiUrl + "gameplay/comment/";
export const likeGameplayUrl = apiUrl + "gameplay/like/";
export const unLikeGameplayUrl = apiUrl + "gameplay/unLike/";
export const repostGameplayUrl = apiUrl + "gameplay/repost/";
export const unRepostGameplayUrl = apiUrl + "gameplay/unRepost/";
export const deleteGameplayUrl = apiUrl + "gameplay/";
export const forgotPasswordUrl = apiUrl + "forgotPassword/";
export const resetPasswordUrl = apiUrl + "resetPassword/";
export const fetchTimeLeftUpdateUsernameUrl =
  apiUrl + "user/getTimeLeftUpdateUsername";
export const updateUsernameUrl = apiUrl + "user/updateUsername";
export const getGameplaysUserGamesUrl =
  apiUrl + "gameplays/getGameplaysUserGames/";
export const fetchGameplaysByGameUrl = apiUrl + "gameplays/getUserGameplays/";

import { useState, useEffect, useRef } from "react";
import {
  AvatarIcon,
  BackButton,
  FilterButton,
  MediaThumbnail,
  SecondaryButton,
  SelectGame,
  QuoteCreatePost,
  SubtitleNavigation,
  Loading,
  UnVerifiedIconFrame,
  ConfirmationModal,
} from "../../components";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createPost } from "../../helpers/fetchPost";
import { Game, Post, PostResponse } from "../../types";
import { fetchAllGames, fetchOwnedGames } from "../../helpers/fetchGame";
import { useAuth } from "../../providers/userAuth";
import { useLocation, useNavigate } from "react-router-dom";
import { TextareaAutosize } from "@mui/base";
import { getDisplayName } from "../../utils/getDisplayName";
import find from "magic-bytes.js";

const postButtons = [
  { title: "Talk", path: "/createtalk" },
  { title: "Play", path: "/createplay" },
];

export const CreatePost = () => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [postResponse, setPostResponse] = useState<PostResponse | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [allGames, setAllGames] = useState<Game[]>([]);
  const [ownedGames, setOwnedGames] = useState<Game[]>([]);
  const [mergedGames, setMergedGames] = useState<Game[]>([]);
  const [selectedGame, setSelectedGame] = useState<Game | undefined>(undefined);
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [quotePost, setQuotePost] = useState<Post | undefined>(undefined);
  const [isDragging, setIsDragging] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const dragCounter = useRef(0);

  const [isContentEmpty, setIsContentEmpty] = useState(true);

  const encodeUsername = (username: string) => {
    return encodeURIComponent(
      username.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
    );
  };

  useEffect(() => {
    const loadGames = async () => {
      try {
        const gamesList = await fetchAllGames();
        const ownedGamesList = await fetchOwnedGames(
          encodeUsername(auth.user.username),
        );

        const allGamesWithHasGame = gamesList.map((game) => ({
          ...game,
          hasGame: false,
        }));

        const ownedGamesWithHasGame = ownedGamesList.map((game) => ({
          ...game,
          hasGame: true,
        }));

        setAllGames(allGamesWithHasGame);
        setOwnedGames(ownedGamesWithHasGame);

        const mergedGames = [
          ...ownedGamesWithHasGame,
          ...allGamesWithHasGame.filter(
            (game) =>
              !ownedGamesWithHasGame.find(
                (ownedGame) => ownedGame.id === game.id,
              ),
          ),
        ];
        setMergedGames(mergedGames);
      } catch (error) {
        console.error("Failed to fetch games:", error);
      }
    };

    loadGames();
  }, [auth]);

  useEffect(() => {
    if (location.state && location.state.quotePost) {
      const quotePostData = location.state.quotePost as Post;
      setQuotePost(quotePostData);
      if (quotePostData.game) {
        setSelectedGame(quotePostData.game);
      }
    }
  }, [location.state]);

  useEffect(() => {
    if (postResponse) {
      if (postResponse.id) {
        toast.success("Post créé avec succès !");
        navigate(`/post/${postResponse.id}`);
      } else {
        toast.error("Échec de la création du post");
      }
    }
  }, [postResponse, navigate]);

  useEffect(() => {
    const handleDragEnter = (event: DragEvent) => {
      event.preventDefault();
      dragCounter.current += 1;
      if (dragCounter.current === 1) {
        setIsDragging(true);
      }
    };

    const handleDragLeave = (event: DragEvent) => {
      event.preventDefault();
      dragCounter.current -= 1;
      if (dragCounter.current === 0) {
        setIsDragging(false);
      }
    };

    const handleDragOver = (event: DragEvent) => {
      event.preventDefault();
    };

    const handleDrop = (event: DragEvent) => {
      event.preventDefault();
      dragCounter.current = 0;
      setIsDragging(false);

      if (!event.dataTransfer) return;

      const files = Array.from(event.dataTransfer.files);
      const oversizedFiles = files.filter(
        (file) => file.size > 50 * 1024 * 1024,
      );
      if (oversizedFiles.length > 0) {
        toast.error("Chaque fichier doit être inférieur à 50 Mo", {
          position: "top-center",
          autoClose: 3000,
        });
        return;
      }

      files.forEach(async (file) => {
        const arrayBuffer = await file.arrayBuffer();
        const uint8Array = new Uint8Array(arrayBuffer);
        const result = find(uint8Array);
        const mimeType = result.length > 0 ? result[0].mime : null;

        if (mimeType === "video/x-matroska") {
          toast.error("Les fichiers MKV ne sont pas autorisés", {
            position: "top-center",
            autoClose: 3000,
          });
        } else if (selectedFiles.length + files.length > 4) {
          toast.error("Veuillez mettre au maximum 4 médias", {
            position: "top-center",
            autoClose: 3000,
          });
        } else {
          setSelectedFiles([...selectedFiles, file]);
        }
      });
    };

    document.addEventListener("dragenter", handleDragEnter);
    document.addEventListener("dragleave", handleDragLeave);
    document.addEventListener("dragover", handleDragOver);
    document.addEventListener("drop", handleDrop);

    return () => {
      document.removeEventListener("dragenter", handleDragEnter);
      document.removeEventListener("dragleave", handleDragLeave);
      document.removeEventListener("dragover", handleDragOver);
      document.removeEventListener("drop", handleDrop);
    };
  }, [selectedFiles]);

  const handleFileInputClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const files = event.target.files;
    const newFiles = Array.from(files || []);

    const oversizedFiles = newFiles.filter(
      (file) => file.size > 50 * 1024 * 1024,
    );
    if (oversizedFiles.length > 0) {
      toast.error("Chaque fichier doit être inférieur à 50 Mo", {
        position: "top-center",
        autoClose: 3000,
      });
      return;
    }

    for (const file of newFiles) {
      const arrayBuffer = await file.arrayBuffer();
      const uint8Array = new Uint8Array(arrayBuffer);
      const result = find(uint8Array);
      const mimeType = result.length > 0 ? result[0].mime : null;

      if (mimeType === "video/x-matroska") {
        toast.error("Les fichiers MKV ne sont pas autorisés", {
          position: "top-center",
          autoClose: 3000,
        });
      } else if (selectedFiles.length + newFiles.length > 4) {
        toast.error("Veuillez mettre au maximum 4 médias", {
          position: "top-center",
          autoClose: 3000,
        });
      } else {
        setSelectedFiles([...selectedFiles, file]);
      }
    }
  };

  const handleDeleteClick = (index: number) => {
    const newSelectedFiles = [...selectedFiles];
    newSelectedFiles.splice(index, 1);
    setSelectedFiles(newSelectedFiles);
  };

  const handleConfirm = () => {
    setShowConfirmationModal(false);
    setIsActionLoading(true);
    navigate("/creategameplay", {
      state: {
        content: textareaRef.current?.value || "",
        gameId: selectedGame?.id || "",
        files: selectedFiles,
        quotePostId: quotePost?.id,
      },
    });
  };

  const handleCancelAndPost = async () => {
    setShowConfirmationModal(false);
    setIsActionLoading(true);
    const content = textareaRef.current?.value;
    try {
      const data = await createPost(
        content || "",
        selectedGame?.id || "",
        selectedFiles,
        undefined,
        quotePost?.id,
      );
      setPostResponse(data);
    } catch (error) {
      console.error(error);
      toast.error("Échec de la création du post");
      setIsActionLoading(false);
    }
  };

  const handlePost = async () => {
    const content = textareaRef.current?.value;
    if (content) {
      if (selectedGame && selectedFiles.length > 0) {
        setShowConfirmationModal(true);
      } else {
        setIsLoading(true);
        try {
          const data = await createPost(
            content,
            selectedGame?.id || "",
            selectedFiles,
            undefined,
            quotePost?.id,
          );
          setPostResponse(data);
        } catch (error) {
          console.error(error);
          toast.error("Échec de la création du post");
          setIsLoading(false);
        }
      }
    } else {
      toast.error("Le contenu du post ne peut pas être vide");
    }
  };

  const isPseudoVerified = () => {
    if (!selectedGame) return false;
    const ownedGame = ownedGames.find((game) => game.id === selectedGame.id);
    return selectedGame.hasGame && ownedGame && !ownedGame.isPseudoVerified;
  };

  const handleTextareaChange = () => {
    const content = textareaRef.current?.value;
    setIsContentEmpty(!content);
  };

  const getDisplayNameWithQuotePost = () => {
    if (quotePost?.game && quotePost.game.id) {
      const ownedGame = ownedGames.find(
        (game) => game.id === quotePost.game.id,
      );
      if (ownedGame) {
        return getDisplayName(auth.user, ownedGame);
      }
    }
    return getDisplayName(auth.user, selectedGame);
  };

  return (
    <div className="flex min-h-[100vh] w-full flex-col items-center">
      {isDragging && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div
            className="rounded-lg p-4"
            style={{
              background:
                "linear-gradient(293deg, #402C48 0.12%, #9552A9 117.69%) padding-box, linear-gradient(to bottom right, rgba(198, 182, 213, 0.91), #402C48 40%) border-box",
            }}
          >
            <p className="h3-mobile">Déposez votre fichier ici</p>
          </div>
        </div>
      )}
      <div className="flex w-full max-w-[680px] flex-col gap-y-4 px-5 pt-[40px]">
        <div className="flex items-center justify-between self-stretch">
          <BackButton />
        </div>
        <div className="flex flex-col items-start gap-y-4 self-stretch">
          <div className="flex w-full items-center justify-between">
            <div className="flex items-start gap-x-2">
              <SubtitleNavigation buttons={postButtons} secondary={true} />
            </div>
            <div>
              <SecondaryButton
                label="Poster"
                onClick={handlePost}
                disabled={isContentEmpty || isLoading || isActionLoading}
              />
            </div>
          </div>
          <div className="z-[10] flex flex-col items-start gap-y-4 self-stretch rounded-[20px] bg-[rgba(245,235,255,0.10)] px-5 pb-5 pt-2 shadow-[inset_0_1px_2px_rgba(0,0,0,0.25)] backdrop-blur-[10.5px]">
            <div className="flex flex-col items-start gap-y-2 self-stretch">
              <div className="flex items-center gap-x-4 self-stretch">
                <div className="relative">
                  <AvatarIcon logo={auth?.user?.logo} className="avatar-xs" />
                  {selectedGame ? (
                    <div className="game-thumbnail-xxs absolute bottom-[-7px] left-[23px] border-[0.5px] border-[rgba(198,182,213,0.50)] shadow-[-1px_-1px_4px_rgba(0,0,0,0.25)]">
                      <div
                        className="z-1 flex h-[22px] w-[22px] items-start rounded-[4px]"
                        style={{
                          background: `url(${selectedGame.logo}) 50% / cover no-repeat`,
                        }}
                      ></div>
                    </div>
                  ) : null}
                </div>
                <div className="flex flex-col">
                  <div className="flex items-center gap-x-2 self-stretch">
                    <p className="h4-mobile cursor-pointer self-stretch">
                      {getDisplayNameWithQuotePost()}
                    </p>
                    {isPseudoVerified() && <UnVerifiedIconFrame />}
                  </div>
                  <p className="label-s-400 cursor-pointer opacity-90">
                    {selectedGame?.name}
                  </p>
                </div>
              </div>
              <div className="flex w-full">
                <TextareaAutosize
                  ref={textareaRef}
                  placeholder="Rédige ton post"
                  maxRows={7}
                  className="label-m-400 mb-[1px] flex grow select-text resize-none self-stretch break-words bg-transparent pb-2 pt-2 opacity-50 focus:opacity-100 focus:outline-none"
                  style={{ minHeight: "30px", maxHeight: "180px" }}
                  onChange={handleTextareaChange}
                />
              </div>
              <div className="flex gap-x-2 overflow-x-auto">
                {selectedFiles.slice(0, 4).map((file, index) => (
                  <MediaThumbnail
                    isVideo={file.type.includes("video")}
                    key={index}
                    banner={URL.createObjectURL(file)}
                    size="m"
                    showSelectionIndicator={true}
                    onClick={() => handleDeleteClick(index)}
                  />
                ))}
              </div>
            </div>
            {quotePost && <QuoteCreatePost quotePost={quotePost} />}
            <div className="z-[1000] flex flex-col items-start gap-y-1 self-stretch">
              <p className="label-s-600">Jeu</p>
              <SelectGame
                items={mergedGames}
                onSelect={(game) => setSelectedGame(game)}
                selectedItem={selectedGame}
                disabled={!!quotePost?.game}
              />
            </div>
          </div>
          <div className="z-1 flex items-center gap-x-2 self-stretch">
            <FilterButton
              label="Videos / Images"
              isActive={false}
              onClick={handleFileInputClick}
            />

            <input
              type="file"
              accept="image/*,video/*"
              ref={fileInputRef}
              onChange={handleFileInputChange}
              multiple
              className="hidden"
            />
          </div>
          {(isLoading || isActionLoading) && (
            <div className="flex w-full justify-center">
              <Loading />
            </div>
          )}
        </div>
      </div>
      {showConfirmationModal && (
        <ConfirmationModal
          onConfirm={handleConfirm}
          onCancel={handleCancelAndPost}
          title="Convertir en Gameplay"
          message="Vous avez sélectionné un jeu, ajouté une vidéo et du contenu. Voulez-vous convertir ce post en gameplay ?"
          confirmLabel="Le convertir en Gameplay"
          cancelLabel="Non garder le post"
        />
      )}
      <ToastContainer />
      <div className="h-1 w-1">{/* <VideoEditor /> */}</div>
    </div>
  );
};

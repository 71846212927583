import { useQuery } from "react-query";
import {
  Loading,
  PostCard,
  BottomBar,
  GameplayCard,
  SecondaryButton,
  SubtitleNavigation,
  CreateContent,
} from "../../components";
import { fetchTimeline } from "../../helpers/fetchPost";
import { fetchGameplayTimeline } from "../../helpers/fetchGameplay";
import { PostTimeline } from "../../types/Post";
import { useEffect, useState } from "react";
import { useAuth } from "../../providers/userAuth";
import { User, Gameplay } from "../../types";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

export const HomePage = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [user, setUser] = useState<User>();
  const [selectedCategory, setSelectedCategory] = useState("Recommandations");

  const {
    data: posts,
    isLoading: isLoadingPosts,
    error: errorPosts,
  } = useQuery<PostTimeline[], Error>("posts", fetchTimeline);

  const {
    data: gameplays,
    isLoading: isLoadingGameplays,
    error: errorGameplays,
  } = useQuery<Gameplay[], Error>("gameplays", fetchGameplayTimeline);

  useEffect(() => {
    setUser(auth.user);
  }, [auth]);

  if (errorPosts) {
    return <div className="h1-mobile">{errorPosts.message}</div>;
  }

  if (errorGameplays) {
    return <div className="h1-mobile">{errorGameplays.message}</div>;
  }

  const navigationList = [
    { title: "Recommandations" },
    { title: "Talks" },
    { title: "Plays" },
  ];

  const sortedPosts =
    posts?.sort((a, b) => {
      const dateA = new Date(a.created_at).getTime();
      const dateB = new Date(b.created_at).getTime();
      return dateB - dateA;
    }) || [];

  const sortedGameplays =
    gameplays?.sort((a, b) => {
      const dateA = new Date(a.created_at).getTime();
      const dateB = new Date(b.created_at).getTime();
      return dateB - dateA;
    }) || [];

  const mergedTimeline = [];
  const maxLength = Math.max(sortedPosts.length, sortedGameplays.length);

  for (let i = 0; i < maxLength; i++) {
    if (i < sortedPosts.length) {
      mergedTimeline.push({ type: "post", content: sortedPosts[i] });
    }
    if (i < sortedGameplays.length) {
      mergedTimeline.push({ type: "gameplay", content: sortedGameplays[i] });
    }
  }

  const filteredTimeline =
    selectedCategory === "Recommandations"
      ? mergedTimeline
      : selectedCategory === "Posts"
        ? mergedTimeline.filter((item) => item.type === "post")
        : mergedTimeline.filter((item) => item.type === "gameplay");

  const navigateToSignIn = () => {
    navigate(`/signin`);
  };

  const navigateToSignUp = () => {
    navigate(`/inscription`);
  };

  if (!auth.user) {
    return (
      <div className="flex min-h-[100vh] w-full flex-col items-center justify-center px-5 py-10">
        <p className="h1-mobile mb-4 text-center">
          Nous sommes désolés, pour le moment vous devez être connecté pour
          consulter le site.
        </p>
        <div className="flex gap-4">
          <SecondaryButton label="Connexion" onClick={navigateToSignIn} />
          <SecondaryButton label="Inscription" onClick={navigateToSignUp} />
        </div>
      </div>
    );
  }

  return (
    <div className="flex min-h-[100vh] w-full flex-col max-mobile:min-h-[calc(100svh-58px)] max-mobile:pt-[60px]">
      {isLoadingPosts || (isLoadingGameplays && <Loading />)}

      <div className="flex w-full flex-col items-center justify-center gap-y-4 px-5 pb-14 min-745:pt-6">
        <SubtitleNavigation
          buttons={navigationList}
          secondary={true}
          padding="none"
          onSelect={(title) => setSelectedCategory(title)}
          activeButton={selectedCategory}
        />
        <CreateContent />
        <span className="h-[1px] w-full max-w-[610px] bg-[rgba(198,182,213,0.25)]"></span>
        {filteredTimeline.map((item, index) =>
          item.type === "post" ? (
            <PostCard key={index} post={item.content as PostTimeline} />
          ) : (
            <GameplayCard key={index} gameplay={item.content as Gameplay} />
          ),
        )}
      </div>
      <BottomBar />
      <ToastContainer />
    </div>
  );
};

type ChevronIconProps = {
  className?: string;
  orientation?: "up" | "down" | "left" | "right";
  onClick?: () => void;
};

export const ChevronIcon: React.FC<ChevronIconProps> = ({
  onClick,
  className = "",
  orientation = "down",
}) => {
  const getRotation = (orientation: string) => {
    switch (orientation) {
      case "up":
        return "rotate-180";
      case "left":
        return "rotate-90";
      case "right":
        return "-rotate-90";
      case "down":
      default:
        return "rotate-0";
    }
  };

  return (
    <svg
      className={`${className} ${getRotation(orientation)}`}
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: `rotate(${getRotation(orientation)})` }}
      onClick={onClick}
    >
      <path
        d="M1 1L7 7L13 1"
        stroke="#F8EFFB"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ChevronBackButtonIcon: React.FC<ChevronIconProps> = ({
  onClick,
  className = "",
  orientation = "down",
}) => {
  const getRotation = (orientation: string) => {
    switch (orientation) {
      case "right":
        return "rotate-180";
      case "up":
        return "rotate-90";
      case "down":
        return "-rotate-90";
      case "left":
      default:
        return "rotate-0";
    }
  };

  return (
    <svg
      className={`${className} ${getRotation(orientation)}`}
      width="18"
      height="24"
      viewBox="0 0 18 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transform: `rotate(${getRotation(orientation)})`,
        textShadow: "0px 4px 5px rgba(0, 0, 0)",
      }}
      onClick={onClick}
    >
      <g filter="url(#filter0_d_599_8487)">
        <path
          d="M12 4L6 10L12 16"
          stroke="#F8EFFB"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_599_8487"
          x="0"
          y="0"
          width="18"
          height="24"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.45 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_599_8487"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_599_8487"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export const ChevronLandingPage = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="96"
      height="94"
      viewBox="0 0 96 94"
      fill="none"
    >
      <g filter="url(#filter0_d_1930_18121)">
        <g filter="url(#filter1_i_1930_18121)">
          <path
            d="M49.8265 53.493C49.0015 54.993 46.8462 54.993 46.0212 53.493L33.7699 31.2179C32.9739 29.7707 34.0209 28 35.6725 28L60.1752 28C61.8268 28 62.8738 29.7707 62.0778 31.2179L49.8265 53.493Z"
            fill="url(#paint0_linear_1930_18121)"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_1930_18121"
          x="-1.15789"
          y="0.368422"
          width="98.1634"
          height="95.2689"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5.52632" />
          <feGaussianBlur stdDeviation="16.5789" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.592157 0 0 0 0 0.180392 0 0 0 0 0.713726 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1930_18121"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1930_18121"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_i_1930_18121"
          x="33.498"
          y="28"
          width="28.8516"
          height="28.0648"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.44762" />
          <feGaussianBlur stdDeviation="0.72381" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.349139 0 0 0 0 0.0964713 0 0 0 0 0.601807 0 0 0 0.62 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_1930_18121"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1930_18121"
          x1="48"
          y1="16.5"
          x2="48"
          y2="66"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-Color="#F8EFFB" />
          <stop offset="0.85" stopColor="#EBD9F1" stopOpacity="0.6" />
        </linearGradient>
      </defs>
    </svg>
  );
};

import { useState, useRef, useEffect } from "react";
import { Post, User } from "../../../types";
import { useAuth } from "../../../providers/userAuth";
import { useNavigate } from "react-router-dom";
import {
  DotsIconPost,
  RepostIcon,
  CommentIcon,
  LikeIcon,
  ShareIcon,
  AvatarIcon,
  PostImage,
  UnVerifiedIconFrame,
  GetFileType,
  QuoteIcon,
  LinkIcon,
  QuotePostCard,
} from "../..";
import {
  unLikePost,
  likePost,
  repostPost,
  unRepostPost,
} from "../../../helpers/fetchPost";
import { PostOptions } from "./PostOptions";
import React from "react";
import { getDisplayName } from "../../../utils/getDisplayName";
import { formatDate } from "../../../utils/dateUtils";

interface FileWithLink {
  link: string;
}

interface PostCardProps {
  post: Post;
  userwhoreposted?: string;
  disableNavigation?: boolean;
  showInteractions?: boolean;
  repostedPosts?: boolean;
  likedPosts?: boolean;
  bottomBorder?: boolean;
  onDeletePost?: (postId: string) => void;
}

export const PostCard: React.FC<PostCardProps> = ({
  post,
  userwhoreposted,
  disableNavigation = false,
  showInteractions = true,
  repostedPosts = false,
  likedPosts = false,
  bottomBorder = true,
  onDeletePost,
}) => {
  const navigate = useNavigate();
  const auth = useAuth();
  const [user, setUser] = useState<User>();
  const [isLiked, setIsLiked] = useState(post.liked);
  const [isReposted, setIsReposted] = useState(post.reposted);
  const [likeCount, setLikeCount] = useState(post.likeCount);
  const [repostCount, setRepostCount] = useState(post.repostCount);
  const [showOverlay, setShowOverlay] = useState(false);
  const [showShareOverlay, setShowShareOverlay] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);
  const [showPostOptions, setShowPostOptions] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const filesWithTypes = GetFileType(post.files as FileWithLink[]);
  const overlayRef = React.createRef<HTMLDivElement>();
  const likeTimeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    setUser(auth.user);
  }, [auth]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        overlayRef.current &&
        !overlayRef.current.contains(event.target as Node)
      ) {
        setShowOverlay(false);
        setShowShareOverlay(false);
        setIsDisabled(true);
        setTimeout(() => setIsDisabled(false), 10);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [overlayRef]);

  const handleLike = () => {
    if (isDisabled) return;

    const previousLikedState = isLiked;
    const previousLikeCount = likeCount;

    setIsLiked(!previousLikedState);
    setLikeCount(previousLikedState ? likeCount - 1 : likeCount + 1);

    if (likeTimeout.current) {
      clearTimeout(likeTimeout.current);
    }

    likeTimeout.current = setTimeout(async () => {
      try {
        if (previousLikedState) {
          await unLikePost(post.id);
        } else {
          await likePost(post.id);
        }
      } catch (error) {
        console.error("Error toggling like:", error);
        setIsLiked(previousLikedState);
        setLikeCount(
          previousLikedState ? previousLikeCount : previousLikeCount,
        );
      }
    }, 500);
  };

  const handleRepost = async () => {
    try {
      if (isReposted) {
        await unRepostPost(post.id);
        setIsReposted(false);
        setRepostCount((prev) => Math.max(prev - 1, 0));
      } else {
        await repostPost(post.id);
        setIsReposted(true);
        setRepostCount((prev) => prev + 1);
      }
      setShowOverlay(false);
      setIsDisabled(true);
      setTimeout(() => setIsDisabled(false), 10);
    } catch (error) {
      console.error("Error toggling repost:", error);
    }
  };

  const handleCopyLink = (e: React.MouseEvent) => {
    e.stopPropagation();

    const link = `${window.location.origin}/post/${post.id}`;
    navigator.clipboard.writeText(link).then(() => {
      setLinkCopied(true);
    });
    setIsDisabled(true);
  };

  const encodeUsername = (username: string) => {
    return encodeURIComponent(
      username.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
    );
  };

  const navigatePlayerPage = () => {
    navigate(`/user/${encodeUsername(post.user.username)}`);
  };

  const navigateUserReposted = () => {
    navigate(`/user/${userwhoreposted}`);
  };

  const navigateGamePage = () => {
    navigate(`/game/${post.game.id}/${post.game.name}`);
  };

  const navigateToPost = () => {
    if (isDisabled) return;
    if (!disableNavigation) {
      navigate(`/talk/${post.id}`);
    }
  };

  const showUserNameOrPseudo = post.game ? post.user.pseudo === null : false;

  return (
    <div
      className={`flex w-full max-w-[610px] flex-col gap-2 rounded-lg bg-[rgba(255,255,255,0.10)] outline outline-1 outline-[rgba(255,255,255,0.20)] ${
        bottomBorder && "border-b border-b-[rgba(245,235,255,0.10)]"
      } pb-3 pt-3 
        ${disableNavigation ? "" : "cursor-pointer rounded"} ${
          disableNavigation || isDisabled ? "" : ""
        }`}
      onClick={navigateToPost}
    >
      {isDisabled && <div className="fixed inset-0 z-[9] bg-transparent"></div>}
      {repostedPosts && post.user && (
        <div className="flex items-center gap-x-2 px-4 opacity-50">
          <RepostIcon />
          <p className="label-s-600">{userwhoreposted} a reposté</p>
        </div>
      )}
      {likedPosts && post.user && (
        <div className="flex items-center gap-x-2 px-4 opacity-50">
          <LikeIcon />
          <p className="label-s-600">{userwhoreposted} a liké</p>
        </div>
      )}
      <div className="flex items-center justify-start gap-x-3 self-stretch px-4">
        <div
          className="relative cursor-pointer"
          onClick={(e) => e.stopPropagation()}
        >
          <AvatarIcon
            logo={post.user.logo}
            className="avatar-s"
            onClick={navigatePlayerPage}
          />
          {post.game ? (
            <div
              className="game-thumbnail-xxs absolute bottom-[-1px] left-[28px] border-[0.5px] border-[rgba(198,182,213,0.50)] shadow-[-1px_-1px_4px_rgba(0,0,0,0.25)] hover:border-[rgba(255,255,255,0.6)]"
              onClick={navigateGamePage}
            >
              <div
                className="z-1 flex h-[22px] w-[22px] items-start rounded-[4px]"
                style={{
                  background: `url(${post.game.logo}) 50% / cover no-repeat`,
                }}
              ></div>
            </div>
          ) : null}
        </div>
        <div className="flex w-full flex-col items-center self-stretch">
          <div className="flex items-center justify-between gap-x-2 self-stretch">
            <div
              className="flex items-center gap-x-2"
              onClick={(e) => e.stopPropagation()}
            >
              <p
                className="h4-mobile cursor-pointer self-stretch hover:text-[#F78888]"
                onClick={navigatePlayerPage}
              >
                {getDisplayName(post.user, post.game)}
              </p>
              {post.game &&
                post.user.isPseudoVerified === false &&
                post.user.pseudo && <UnVerifiedIconFrame />}
            </div>
            <div className="flex items-center gap-x-2">
              <div className="flex items-center self-stretch">
                <p className="label-s-400 whitespace-nowrap rounded-lg bg-[rgba(0,0,0,0.4)] p-1">
                  Talk
                </p>
              </div>
              {auth.user?.id === post.user.id && (
                <div
                  className={`relative z-[100] flex w-[30px] flex-col items-center justify-center px-1.5 py-1 ${
                    showPostOptions ? "" : "cursor-pointer opacity-50"
                  }`}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowPostOptions(!showPostOptions);
                  }}
                >
                  <DotsIconPost />
                  {showPostOptions && (
                    <PostOptions
                      userId={post.user.id}
                      name={post.user.username}
                      postId={post.id}
                      isUserPost={auth.user?.id === post.user.id}
                      onClose={() => setShowPostOptions(false)}
                      onDeletePost={onDeletePost}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
          <div
            className={`flex w-full items-center ${post.game ? "justify-between" : "justify-end"} `}
            onClick={(e) => post.game && e.stopPropagation()}
          >
            {post.game ? (
              <p
                className="label-s-400 cursor-pointer self-stretch opacity-50 hover:underline"
                onClick={navigateGamePage}
              >
                {post.game.name}
              </p>
            ) : null}
            <p className="label-s-400 opacity-40">
              {formatDate(post.created_at.toString())}
            </p>
          </div>
        </div>
      </div>
      {post.parentPost && !Array.isArray(post.parentPost) && (
        <div className="p-2" onClick={(e) => e.stopPropagation()}>
          <QuotePostCard quotePost={post.parentPost} />
        </div>
      )}
      <p className="paragraph-s-500 self-stretch px-4 pt-1 text-sm">
        {post.content}
      </p>
      {post.quotePost && !Array.isArray(post.quotePost) && (
        <div className="p-2">
          <QuotePostCard quotePost={post.quotePost} cite={true} />
        </div>
      )}
      {post.files && <PostImage files={filesWithTypes} />}
      <div
        className={`flex items-center self-stretch px-4 ${
          !showUserNameOrPseudo && post.game ? "justify-between" : "justify-end"
        }`}
      >
        {post.game && !showUserNameOrPseudo && (
          <p
            className="label-s-400 cursor-pointer opacity-40 hover:underline"
            onClick={(e) => {
              e.stopPropagation();
              navigatePlayerPage();
            }}
          >
            @{post.user.username}
          </p>
        )}
      </div>
      {showInteractions && (
        <div className="z-[10] flex items-center justify-between self-stretch px-4 pt-1 opacity-90">
          <div
            className="flex cursor-pointer items-center gap-2"
            onClick={(e) => e.stopPropagation()}
          >
            <CommentIcon onClick={navigateToPost} />
            <span
              className="label-s-400 cursor-pointer text-xs"
              onClick={navigateToPost}
            >
              {post.childPostCount}
            </span>
          </div>
          <div
            className="flex cursor-pointer items-center gap-2"
            onClick={(e) => {
              e.stopPropagation();
              handleLike();
            }}
          >
            <LikeIcon onClick={handleLike} liked={isLiked} />
            <span
              className={`label-s-400 cursor-pointer text-xs ${
                isLiked ? "text-[#F78888]" : ""
              }`}
            >
              {likeCount}
            </span>
          </div>
          <div
            className="relative z-[10] flex cursor-pointer items-center gap-2"
            onClick={(e) => {
              e.stopPropagation();
              setShowOverlay(!showOverlay);
              setIsDisabled(true);
            }}
          >
            <RepostIcon reposted={isReposted} />
            <span
              className={`label-s-400 cursor-pointer text-xs ${
                isReposted ? "text-[rgba(0,186,124)]" : ""
              }`}
            >
              {repostCount}
            </span>
            {showOverlay && (
              <div
                ref={overlayRef}
                className="absolute right-0 top-[-90px] z-[1000] flex w-[200px] flex-col items-start gap-y-3 rounded-lg border-[0.5px] border-[rgba(143,142,142,0.50)] px-4 py-3 backdrop-blur-[6.5px]"
                style={{
                  background:
                    "linear-gradient(293deg, rgba(40, 12, 49, 1) 0.12%, rgba(151, 46, 182, 1) 117.69%)",
                }}
                onClick={(e) => e.stopPropagation()}
              >
                <div
                  className="flex cursor-pointer items-center gap-x-[10px]"
                  onClick={handleRepost}
                >
                  <RepostIcon />
                  <p className="nav-menu-500">
                    {isReposted ? "Annuler la republication" : "Republier"}
                  </p>
                </div>
                <div
                  className="flex cursor-pointer items-center gap-x-[10px]"
                  onClick={() =>
                    navigate("/createpost", { state: { quotePost: post } })
                  }
                >
                  <QuoteIcon />
                  <p className="nav-menu-500">Citer</p>
                </div>
              </div>
            )}
          </div>
          <div
            className="relative z-[10] flex cursor-pointer items-center"
            onClick={(e) => {
              e.stopPropagation();
              setShowShareOverlay(!showShareOverlay);
              setIsDisabled(true);
            }}
          >
            <ShareIcon />
            {showShareOverlay && (
              <div
                ref={overlayRef}
                className="absolute right-0 top-[-50px] z-[1000] flex w-[200px] flex-col items-start gap-y-3 rounded-lg border-[0.5px] border-[rgba(143,142,142,0.50)] px-4 py-3 backdrop-blur-[6.5px]"
                style={{
                  background:
                    "linear-gradient(293deg, rgba(40, 12, 49, 1) 0.12%, rgba(151, 46, 182, 1) 117.69%)",
                }}
                onClick={(e) => e.stopPropagation()}
              >
                <div
                  className="flex cursor-pointer items-center gap-x-[10px]"
                  onClick={handleCopyLink}
                >
                  <LinkIcon />
                  <p className="nav-menu-500">
                    {linkCopied ? "Lien copié" : "Copier le lien"}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

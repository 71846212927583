// GameplaysByGamePage.tsx

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchGameplaysByGame } from "../../helpers/fetchUser";
import { GameplayPreviewCard, SubtitleNavigation } from "../../components";
import { Gameplay } from "../../types/Gameplay";
import { UserLayout } from "./UserLayout";
import axios, { AxiosResponse } from "axios";
import { fetchGameByIdUrl } from "../../constants/api";

const postButtons = [
  { title: "Date", path: "", exact: true }, // Actif uniquement sur `/user/:username/plays/jeux`
  { title: "Jeux", path: "jeux", exact: false }, // Actif sur `/user/:username/plays/jeux` et ses sous-chemins
];

export const GameplaysByGamePage = () => {
  const { username, gameId } = useParams<{
    username: string;
    gameId: string;
  }>();
  const navigate = useNavigate();
  const [gameplays, setGameplays] = useState<Gameplay[]>([]);
  const [gameName, setGameName] = useState<string>("");
  const [gameThumbnail, setGameThumbnail] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    const fetchGameplays = async () => {
      if (username && gameId) {
        try {
          const gameplaysData = await fetchGameplaysByGame(username, gameId);

          setGameplays(gameplaysData);

          if (gameplaysData.length > 0 && gameplaysData[0].game) {
            setGameName(gameplaysData[0].game.name);
            setGameThumbnail(gameplaysData[0].game.logo);
          } else {
            try {
              const gameResponse: AxiosResponse<{
                name: string;
                logo: string;
              }> = await axios.get(
                `${fetchGameByIdUrl}${encodeURIComponent(gameId)}`,
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  },
                },
              );
              const game = gameResponse.data;
              setGameName(game.name);
              setGameThumbnail(game.logo);
            } catch (gameError: any) {
              console.error("Error fetching game details:", gameError);
              setGameName("le Jeu");
              setGameThumbnail(""); // Optionnellement, définissez une image par défaut
            }
          }
        } catch (error: any) {
          console.error(
            "Erreur lors de la récupération des gameplays pour ce jeu",
            error,
          );
          setErrorMessage(error.message || "Erreur inconnue");
        }
      }
    };

    fetchGameplays();
  }, [username, gameId]);

  const handleNavigation = (path: string) => {
    if (path === "jeux") {
      navigate(`/user/${username}/plays/jeux`);
    } else {
      navigate(`/user/${username}/plays`);
    }
  };

  const handleGameClick = (gameId: string) => {
    navigate(`/user/${username}/plays/jeux/${gameId}`);
  };

  return (
    <UserLayout>
      <div className="flex flex-col items-center gap-y-4 px-5 pb-8">
        <div className="flex items-start gap-x-2 max-mobile:pt-2">
          <SubtitleNavigation
            buttons={postButtons.map((button) => ({
              ...button,
              path: `/user/${username}/plays${button.path ? `/${button.path}` : ""}`,
            }))}
            secondary={true}
            padding="p-0"
          />
        </div>
        {gameName && (
          <div className="flex items-center gap-x-2">
            {gameThumbnail && (
              <img src={gameThumbnail} alt={gameName} className="h-8 w-8" />
            )}
            <h2 className="h2-mobile">{gameName}</h2>
          </div>
        )}

        {errorMessage && (
          <p className="text-red-500 text-center">{errorMessage}</p>
        )}

        <div className="grid w-full grid-cols-1 gap-4 pb-10 sm:grid-cols-2 lg:grid-cols-3">
          {gameplays.length > 0 ? (
            gameplays.map((gameplay) => (
              <div className="flex flex-wrap items-center justify-start gap-3 max-mobile:justify-center">
                <GameplayPreviewCard
                  key={gameplay.id}
                  gameplay={gameplay}
                  bottomBorder={false}
                />
              </div>
            ))
          ) : (
            <p className="paragraph-s-400 text-center">
              {errorMessage
                ? "Aucun gameplay disponible pour ce jeu."
                : "Chargement..."}
            </p>
          )}
        </div>
      </div>
    </UserLayout>
  );
};

import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { fetchUserPosts } from "../../helpers/fetchUser";
import { PostCard, ButtonSimple } from "../../components";
import { Post } from "../../types";
import { UserLayout } from "./UserLayout";
import { useAuth } from "../../providers/userAuth";
import { useMediaQuery } from "react-responsive";

export const UserPostsPage = () => {
  const { username } = useParams();
  const auth = useAuth();
  const navigate = useNavigate();
  const [posts, setPosts] = useState<Post[]>([]);
  const isMobile = useMediaQuery({ maxWidth: 745 });

  useEffect(() => {
    const fetchPosts = async () => {
      if (username) {
        try {
          const userPosts = await fetchUserPosts(username);
          let allPosts = [...userPosts.posts, ...userPosts.repostedPosts];
          if (auth.user?.username === username) {
            allPosts = [...allPosts, ...userPosts.likedPosts];
          }
          const uniquePosts = filterUniquePosts(allPosts);
          const sortedPosts = uniquePosts.sort(
            (a, b) =>
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime(),
          );
          setPosts(sortedPosts);
        } catch (error) {
          console.error("Erreur lors de la récupération des posts", error);
        }
      }
    };

    fetchPosts();
  }, [username, auth.user]);

  const filterUniquePosts = (allPosts: Post[]) => {
    const postIds = new Set<string>();
    const uniquePosts: Post[] = [];
    for (const post of allPosts) {
      if (!postIds.has(post.id)) {
        uniquePosts.push(post);
        postIds.add(post.id);
      }
    }
    return uniquePosts;
  };

  const handleDeletePost = (postId: string) => {
    setPosts((prevPosts) => prevPosts.filter((post) => post.id !== postId));
  };

  return (
    <UserLayout>
      <div
        className="flex h-full w-full min-745:pt-4 max-tablet:flex-col max-tablet:items-center max-tablet:gap-y-4 max-tablet:px-5 max-tablet:pb-[150px] min-851:items-start min-851:gap-x-8 min-851:pl-8 min-851:pr-5"
        style={{
          background: isMobile
            ? "linear-gradient(0deg, #140029 61.19%, #1A0920 100%)"
            : "none",
        }}
      >
        <div className="flex w-full flex-col items-center pb-8 max-mobile:pt-4 max-tablet:gap-y-8 ">
          <div className="flex w-full flex-col items-center gap-y-4 max-tablet:max-w-[610px] min-851:max-w-[550px]">
            <div className="flex items-center justify-center self-stretch">
              <div className="h2-mobile">
                {auth.user?.username === username ? "Tes talks" : "Talks"}
              </div>
            </div>
            <div className="flex flex-col items-center gap-y-4 self-stretch">
              {posts.length > 0 ? (
                posts.map((post) => (
                  <PostCard
                    key={post.id}
                    post={post}
                    repostedPosts={post.reposted}
                    userwhoreposted={username}
                    likedPosts={
                      auth.user?.username === username ? post.liked : undefined
                    }
                    onDeletePost={handleDeletePost}
                  />
                ))
              ) : (
                <div className="flex flex-col items-center gap-y-4">
                  <p className="paragraph-m-400">
                    {auth.user?.username === username
                      ? "Vous n'avez encore rien posté. Commencez par publier un message!"
                      : "Cet utilisateur n'a encore rien posté."}
                  </p>
                  {auth.user?.username === username && (
                    <ButtonSimple
                      label="Créer un talk"
                      onClick={() => navigate("/createpost")}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </UserLayout>
  );
};

import { useQuery, useQueryClient } from "react-query";
import { PlayerStats, Post, User } from "../../types";
import { fetchOnePost } from "../../helpers/fetchPost";
import {
  BackButton,
  PostCard,
  SecondaryButton,
  FormatTimeDifference,
  OverlayUnauthenticated,
  AvatarIcon,
  TransparentSecondaryButton,
  AddFileChat,
  SendMessage,
  MediaThumbnail,
  Loading,
  BottomBar,
  UserPostGameStat,
  ImagesIcon,
} from "../../components";
import { useNavigate, useParams } from "react-router-dom";
import { CommentCard } from "../../components/Card/PostCard/CommentCard";
import { useState, useEffect, useRef } from "react";
import { useAuth } from "../../providers/userAuth";
import { createPost } from "../../helpers/fetchPost";
import { ToastContainer, toast } from "react-toastify";
import { fetchPlayerStats } from "../../helpers/fetchGame";
import { useMediaQuery } from "react-responsive";
import { Textbox } from "react-inputs-validation";
import "react-inputs-validation/lib/react-inputs-validation.min.css";

const convertMinutesToHours = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  return `${hours} heures`;
};

export const PostPage = () => {
  const { postId } = useParams();
  const [user, setUser] = useState<User>();
  const [hasGameAccount, setHasGameAccount] = useState(true);
  const [commentContent, setCommentContent] = useState("");
  const [commentFiles, setCommentFiles] = useState<File[]>([]);
  const [comments, setComments] = useState<Post[]>([]);
  const [isFocused, setIsFocused] = useState(false);
  const navigate = useNavigate();
  const auth = useAuth();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const queryClient = useQueryClient();

  const isDesktop = useMediaQuery({ minWidth: 851 });

  const {
    data: post,
    isLoading,
    isError,
  } = useQuery<Post, Error>(
    ["post", postId],
    fetchOnePost(postId ? postId : ""),
    {
      onSuccess: (data) => {
        setComments(data.childPost);
      },
    },
  );

  const userId = post?.user.id;
  const gameId = post?.game?.id;

  useEffect(() => {
    setUser(auth.user);
  }, [auth]);

  const {
    data: playerStats,
    isLoading: statsLoading,
    isError: statsError,
  } = useQuery<PlayerStats, Error>(
    ["playerStats", userId, gameId],
    () => fetchPlayerStats(String(gameId), String(userId))(),
    {
      onError: (error: Error & { response?: { status: number } }) => {
        if (error.response?.status === 404) {
          setHasGameAccount(false);
        }
      },
      enabled: !!userId && !!gameId,
    },
  );

  const handleFileInputClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const files = event.target.files;
    if (files && commentFiles.length + files.length > 4) {
      toast.error("Veuillez mettre au maximum 4 médias", {
        position: "top-center",
        autoClose: 3000,
      });
    } else if (files) {
      setCommentFiles([...commentFiles, ...Array.from(files)]);
    }
  };

  const handleDeleteClick = (index: number) => {
    const newCommentFiles = [...commentFiles];
    newCommentFiles.splice(index, 1);
    setCommentFiles(newCommentFiles);
  };

  const handleSendComment = async () => {
    if (commentContent.trim() === "") {
      toast.error("Le contenu du commentaire ne peut pas être vide");
      return;
    }
    try {
      await createPost(commentContent, gameId ?? "", commentFiles, postId);
      toast.success("Commentaire créé avec succès !");
      setCommentContent("");
      setCommentFiles([]);
      queryClient.invalidateQueries(["post", postId]);
    } catch (error) {
      toast.error("Échec de la création du commentaire");
    }
  };

  if (!post) {
    return <div>No post found</div>;
  }

  if (!auth.user) {
    return <OverlayUnauthenticated />;
  }

  const latestComment =
    post.childPost.length > 0
      ? post.childPost.reduce((prev, current) => {
          return new Date(prev.created_at) > new Date(current.created_at)
            ? prev
            : current;
        })
      : null;

  const commentCountLabel =
    post.childPost.length === 0
      ? "Aucun commentaire"
      : post.childPost.length === 1
        ? "1 commentaire"
        : `${post.childPost.length} commentaires`;

  const encodeUsername = (username: string) => {
    return encodeURIComponent(
      username.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
    );
  };

  const navigatePlayerPage = () => {
    navigate(`/user/${encodeUsername(post.user.username)}`);
  };

  return (
    <div className="flex w-full justify-center max-tablet:flex-col min-851:pl-8 min-851:pt-4">
      {isLoading && <Loading />}
      {isError && <div className="h1-mobile">{isError}</div>}
      <div className="flex min-h-[100svh] w-full flex-col gap-y-3 min-851:max-w-[610px] min-851:pr-8">
        <div className="flex flex-col items-center gap-y-4 self-stretch max-mobile:px-5 max-mobile:pt-[60px]">
          <div className="flex w-full max-w-[610px] items-center justify-between pt-2">
            <BackButton />
            {auth.user.id !== post.user.id && (
              <div className="min-745:hidden">
                <SecondaryButton
                  userId={post.user.id}
                  initialFollow={false}
                  actionType="follow"
                />
              </div>
            )}
            <p className="h2-mobile max-mobile:hidden">
              Posts de {post.user.username}
            </p>
          </div>
          <PostCard
            post={post}
            disableNavigation={true}
            bottomBorder={!isDesktop && playerStats ? true : false}
          />
          {playerStats && (
            <div className="w-full min-851:hidden">
              <UserPostGameStat
                post={post}
                playerStats={playerStats}
                hasGameAccount={hasGameAccount}
              />
            </div>
          )}
        </div>

        <div className="flex w-full items-center justify-center max-850:px-5">
          <div
            className={`relative z-[5] flex w-full flex-col gap-y-2 rounded-2xl border border-[0.5px] border-[rgba(198,182,213,0.15)] bg-[rgba(245,235,255,0.10)] px-4 py-2 transition-all duration-300 ${
              isFocused
                ? "justify-centers h-auto ring-1 ring-inset ring-[rgba(198,182,213,0.35)] hover:border-[rgba(198,182,213,0.55)]"
                : "h-12 justify-center"
            }`}
          >
            <div className="flex h-12 items-start">
              <Textbox
                attributesWrapper={{}}
                attributesInput={{
                  id: "comment-textbox",
                  name: "comment-textbox",
                  type: "text",
                  placeholder: "Rédige ton commentaire",
                }}
                value={commentContent}
                onChange={(value: string) => setCommentContent(value)}
                onBlur={() => setIsFocused(true)}
                onFocus={() => setIsFocused(true)}
                validationOption={{
                  name: "Comment",
                  check: false,
                  required: false,
                }}
                classNameInput="label-m-400 flex-grow !border-none !bg-transparent !outline-none focus:opacity-100"
                classNameWrapper="w-full !rounded-md outline-none"
                classNameContainer="label-m-400 bg-transparent !p-0 !rounded-md"
              />
            </div>
            {commentFiles.length > 0 && (
              <div className="flex gap-x-2 overflow-x-auto p-2">
                {commentFiles.slice(0, 4).map((file, index) => (
                  <MediaThumbnail
                    isVideo={file.type.includes("video")}
                    key={index}
                    banner={URL.createObjectURL(file)}
                    size="m"
                    showSelectionIndicator={true}
                    onClick={() => handleDeleteClick(index)}
                  />
                ))}
              </div>
            )}
            {isFocused && (
              <div className="flex w-full items-end justify-between">
                <div onClick={handleFileInputClick}>
                  <ImagesIcon />
                </div>
                <input
                  type="file"
                  accept="image/*,video/*"
                  ref={fileInputRef}
                  onChange={handleFileInputChange}
                  multiple
                  style={{ display: "none" }}
                />
                <SendMessage onClick={handleSendComment} />
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-col justify-center gap-y-2 self-stretch bg-[rgba(151,46,182,0.10)] px-5 pb-5 pt-4">
          <div className="flex items-center justify-center self-stretch">
            <div className="flex w-full max-w-[600px] items-center justify-between self-stretch">
              <p className="h3-mobile">{commentCountLabel}</p>
              {latestComment && (
                <p className="label-s-400 opacity-70">
                  <FormatTimeDifference
                    date={new Date(latestComment.created_at)}
                  />
                </p>
              )}
            </div>
          </div>
          <div className="flex flex-col items-center justify-center self-stretch">
            {post.childPost.map((childPost) => (
              <CommentCard key={childPost.id} post={childPost} />
            ))}
          </div>
        </div>
      </div>

      <div className="flex w-full max-w-[450px] flex-1 flex-col items-start gap-y-4 max-tablet:hidden">
        <div
          className="flex w-full flex-col gap-y-6 rounded-xl p-5"
          style={{
            background:
              "linear-gradient(180deg, rgba(245, 235, 255, 0.10) 0%, rgba(224, 195, 233, 0.10) 100%)",
          }}
        >
          <div className="flex w-full flex-col gap-y-4">
            <div className="flex items-center gap-x-2 self-stretch">
              <AvatarIcon logo={post.user.logo} className="avatar-m" />
              <div className="flex flex-col justify-center gap-y-1 self-stretch">
                <p className="truncate-one-line h2-mobile">
                  {post.user.username}
                </p>
              </div>
            </div>
            <div className="flex w-full items-center gap-x-2">
              <TransparentSecondaryButton
                label="Voir le profil"
                onClick={navigatePlayerPage}
              />
              {auth.user.id !== post.user.id && (
                <SecondaryButton
                  userId={post.user.id}
                  initialFollow={false}
                  actionType="follow"
                />
              )}
            </div>
          </div>
          <UserPostGameStat
            post={post}
            playerStats={playerStats}
            hasGameAccount={hasGameAccount}
          />
        </div>
      </div>
      <BottomBar />
      <ToastContainer />
    </div>
  );
};

import { useState } from "react";
import { CloseFillIcon, NewMessageIcon, TooltipNewMessage } from "..";
import { useNavigate } from "react-router-dom";

type RoundedButtonProps = {
  isMessagery?: boolean;
  onClick?: () => void;
};

export const RoundedButton: React.FC<RoundedButtonProps> = ({
  isMessagery = false,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const navigate = useNavigate();

  const handleButtonClick = () => {
    if (isMessagery) {
      setIsActive(!isActive);
      setShowTooltip(!showTooltip);
    } else {
      navigate("/createtalk");
    }
  };

  return (
    <div>
      <button
        onClick={handleButtonClick}
        className={`relative flex h-[60px] w-[60px] items-center justify-center rounded-full shadow-[0_4px_10px_rgba(0,0,0,0.25)] ${
          !isActive && isMessagery ? "pb-1 pl-1" : ""
        } ${isActive ? "bg-[#573B73]" : "bg-primary-500"}`}
      >
        {isActive ? (
          <CloseFillIcon rotated={false} />
        ) : isMessagery ? (
          <NewMessageIcon />
        ) : (
          <CloseFillIcon rotated={true} />
        )}
      </button>
      {showTooltip && isMessagery && (
        <div className="absolute left-[50%] top-[-160px]">
          <div className="relative left-[-50%]">
            <TooltipNewMessage onClick={handleButtonClick} />
          </div>
        </div>
      )}
    </div>
  );
};

export const RoundedButtonMessagery: React.FC<RoundedButtonProps> = ({
  onClick,
}) => {
  return (
    <div
      className="flex h-7 w-7 cursor-pointer items-center justify-center rounded-full border border-[rgba(198,182,213,0.25)] bg-[rgba(20,0,41,0.50)] shadow-[0_1px_0px_rgba(0,0,0,0.25)]"
      onClick={onClick}
    >
      <CloseFillIcon size={10} rotated={false} />
    </div>
  );
};

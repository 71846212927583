import { useNavigate } from "react-router-dom";
import {
  HomeFillIcon,
  SearchFillIcon,
  GroupFillIcon,
  MessageFillIcon,
  RoundedButton,
} from "..";
import { useAuth } from "../../providers/userAuth";

type BottomBarProps = {
  isMessagery?: boolean;
  hidden?: boolean;
};

export const BottomBar: React.FC<BottomBarProps> = ({
  hidden = false,
  isMessagery = false,
}) => {
  const navigate = useNavigate();
  const { user: authUser } = useAuth();
  const username = authUser?.username;

  const navigateToHomePage = () => {
    navigate(`/home`);
  };

  const navigateToSearchPage = () => {
    navigate(`/search`);
  };

  const navigateToFriendsMenuPage = () => {
    navigate(`/managefriends`);
  };

  const navigateToMessagePage = () => {
    navigate(`/messages`);
  };

  return (
    <ol
      className={`${hidden && "hidden"} fixed bottom-0 z-10 flex w-full items-end justify-between border-t border-t-[#C6B6D540] backdrop-blur-[7px] min-745:hidden`}
      style={{
        background:
          "linear-gradient(92.03deg, rgba(40, 12, 49, 0.8) 8.79%, rgba(33, 16, 51, 0.8) 100%)",
        height: "calc(58px + env(safe-area-inset-bottom))",
      }}
    >
      <div className="flex h-full w-[42%]">
        <li
          className="flex h-full flex-1 flex-col items-center text-center"
          onClick={navigateToHomePage}
        >
          <div className="relative h-8 cursor-pointer">
            <HomeFillIcon />
          </div>
          <p className="label-s-400 mt-[-3px] cursor-pointer opacity-70">
            Home
          </p>
        </li>
        {authUser && (
          <li
            className="flex h-full flex-1 flex-col items-center text-center"
            onClick={navigateToMessagePage}
          >
            <div className="relative h-8 cursor-pointer pt-0.5">
              <MessageFillIcon />
            </div>
            <p className="label-s-400 mt-[-3px] cursor-pointer opacity-70">
              Messages
            </p>
          </li>
        )}
      </div>
      {authUser && (
        <div className="absolute left-[50%] z-10 -translate-x-[50%] transform rounded-full pb-4">
          <RoundedButton isMessagery={isMessagery} />
        </div>
      )}
      <div className="flex h-full w-[42%]">
        {authUser && (
          <li
            className="flex h-full flex-1 flex-col items-center text-center"
            onClick={navigateToFriendsMenuPage}
          >
            <div className="relative flex h-8 cursor-pointer items-center">
              <GroupFillIcon />
            </div>
            <p className="label-s-400 mt-[-3px] cursor-pointer opacity-70">
              Amis
            </p>
          </li>
        )}
        <li
          className="flex h-full flex-1 flex-col items-center text-center"
          onClick={navigateToSearchPage}
        >
          <div className="relative mt-1 h-7 cursor-pointer">
            <SearchFillIcon />
          </div>
          <p className="label-s-400 mt-[-3px] cursor-pointer opacity-70">
            Recherche
          </p>
        </li>
      </div>
    </ol>
  );
};

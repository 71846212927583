type InfoIconProps = {
  size?: number;
  color?: string;
  onMouseEnter?: () => void;
};

export const InfoIcon: React.FC<InfoIconProps> = ({
  size = 24,
  color = "bg-primary-100",
  onMouseEnter,
}) => (
  <div
    className={`flex flex-shrink-0 items-center justify-center gap-[2px] rounded-full ${color} ${size == 18 ? "" : "p-[3px]"} shadow-[0_0_4px_#140029]`}
    style={{
      height: `${size}px`,
      width: `${size}px`,
    }}
    onMouseEnter={onMouseEnter}
  >
    <p
      className={`button-label  ${color == "red" ? "text-white" : "text-primary-900"}`}
    >
      i
    </p>
  </div>
);

export const PaltformConIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="4"
    viewBox="0 0 10 4"
    fill="none"
  >
    <path d="M0 0.5H10V3.5H0V0.5Z" fill="white" />
  </svg>
);

export const PaltformNotConIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
      fill="#4CAF50"
    />
    <path d="M8.57214 5.24133H11.4279V14.7586H8.57214V5.24133Z" fill="white" />
    <path d="M5.24047 8.57263H14.7595V11.4278H5.24047V8.57263Z" fill="white" />
  </svg>
);

import { useEffect, useRef, useState } from "react";
import {
  AvatarIcon,
  MediaThumbnail,
  SecondaryButton,
  SelectGame,
  SubtitleNavigation,
  Loading,
  UnVerifiedIconFrame,
  ImagesIcon,
  AddFillIcon,
  CustomTextbox,
  SwapIcon,
  PlayerSearch,
} from "..";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createPost } from "../../helpers/fetchPost";
import { createGameplay } from "../../helpers/fetchGameplay";
import { fetchAllGames, fetchOwnedGames } from "../../helpers/fetchGame";
import { Game } from "../../types";
import { useAuth } from "../../providers/userAuth";
import { getDisplayName } from "../../utils/getDisplayName";
import { TextareaAutosize } from "@mui/base";
import { IoMdClose } from "react-icons/io";
import find from "magic-bytes.js";

const placeholderPhrases = [
  "Partage ton aventure épique !",
  "Raconte-nous ton exploit héroïque !",
  "Comment as-tu conquis le donjon ?",
  "Décris ta bataille légendaire !",
  "Comment as-tu survécu à l'attaque des dragons ?",
  "Quel monstre as-tu vaincu aujourd'hui ?",
  "Détails de ta quête réussie ?",
  "Raconte ton meilleur moment de jeu !",
  "Comment as-tu sauvé le royaume ?",
  "Partage tes astuces pour battre les boss !",
  "As-tu retrouvé l'anneau unique ?",
];

const getDailyPlaceholder = () => {
  const now = new Date();
  const start = new Date(now.getFullYear(), 0, 0);
  const diff = now.getTime() - start.getTime();
  const oneDay = 1000 * 60 * 60 * 24;
  const dayOfYear = Math.floor(diff / oneDay);
  return placeholderPhrases[dayOfYear % placeholderPhrases.length];
};

export const CreateContent = () => {
  const auth = useAuth();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [selectedGame, setSelectedGame] = useState<Game | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [isContentEmpty, setIsContentEmpty] = useState(true);
  const [allGames, setAllGames] = useState<Game[]>([]);
  const [ownedGames, setOwnedGames] = useState<Game[]>([]);
  const [mergedGames, setMergedGames] = useState<Game[]>([]);
  const [isCreatingPost, setIsCreatingPost] = useState(true);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isLoadingVideo, setIsLoadingVideo] = useState(false);
  const [fileInputDisabled, setFileInputDisabled] = useState(false);
  const [title, setTitle] = useState<string>("");
  const [placeholder, setPlaceholder] = useState<string>(getDailyPlaceholder());
  const [isDragging, setIsDragging] = useState(false);
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
  const dragCounter = useRef(0);

  useEffect(() => {
    const loadGames = async () => {
      try {
        const gamesList = await fetchAllGames();
        const ownedGamesList = await fetchOwnedGames(
          encodeUsername(auth.user.username),
        );

        const allGamesWithHasGame = gamesList.map((game) => ({
          ...game,
          hasGame: false,
        }));

        const ownedGamesWithHasGame = ownedGamesList.map((game) => ({
          ...game,
          hasGame: true,
        }));

        setAllGames(allGamesWithHasGame);
        setOwnedGames(ownedGamesWithHasGame);

        const mergedGames = [
          ...ownedGamesWithHasGame,
          ...allGamesWithHasGame.filter(
            (game) =>
              !ownedGamesWithHasGame.find(
                (ownedGame) => ownedGame.id === game.id,
              ),
          ),
        ];
        setMergedGames(mergedGames);
      } catch (error) {
        console.error("Failed to fetch games:", error);
      }
    };

    loadGames();
  }, [auth]);

  useEffect(() => {
    const handleDragEnter = (event: DragEvent) => {
      event.preventDefault();
      dragCounter.current += 1;
      if (dragCounter.current === 1) {
        setIsDragging(true);
      }
    };

    const handleDragLeave = (event: DragEvent) => {
      event.preventDefault();
      dragCounter.current -= 1;
      if (dragCounter.current === 0) {
        setIsDragging(false);
      }
    };

    const handleDragOver = (event: DragEvent) => {
      event.preventDefault();
    };

    const handleDrop = (event: DragEvent) => {
      event.preventDefault();
      dragCounter.current = 0;
      setIsDragging(false);

      if (!event.dataTransfer) return;

      const files = Array.from(event.dataTransfer.files);
      const oversizedFiles = files.filter(
        (file) => file.size > 50 * 1024 * 1024,
      );
      if (oversizedFiles.length > 0) {
        toast.error("Chaque fichier doit être inférieur à 50 Mo", {
          position: "top-center",
          autoClose: 3000,
        });
        return;
      }

      files.forEach(async (file) => {
        const arrayBuffer = await file.arrayBuffer();
        const uint8Array = new Uint8Array(arrayBuffer);
        const result = find(uint8Array);
        const mimeType = result.length > 0 ? result[0].mime : null;

        if (mimeType === "video/x-matroska") {
          toast.error("Les fichiers MKV ne sont pas autorisés", {
            position: "top-center",
            autoClose: 3000,
          });
        } else if (selectedFiles.length + files.length > 4) {
          toast.error("Veuillez mettre au maximum 4 médias", {
            position: "top-center",
            autoClose: 3000,
          });
        } else {
          setSelectedFiles([...selectedFiles, file]);
        }
      });
    };

    document.addEventListener("dragenter", handleDragEnter);
    document.addEventListener("dragleave", handleDragLeave);
    document.addEventListener("dragover", handleDragOver);
    document.addEventListener("drop", handleDrop);

    return () => {
      document.removeEventListener("dragenter", handleDragEnter);
      document.removeEventListener("dragleave", handleDragLeave);
      document.removeEventListener("dragover", handleDragOver);
      document.removeEventListener("drop", handleDrop);
    };
  }, [selectedFiles]);

  const handleFileInputClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const files = event.target.files;
    const newFiles = Array.from(files || []);

    const oversizedFiles = newFiles.filter(
      (file) => file.size > 50 * 1024 * 1024,
    );
    if (oversizedFiles.length > 0) {
      toast.error("Chaque fichier doit être inférieur à 50 Mo", {
        position: "top-center",
        autoClose: 3000,
      });
      return;
    }

    for (const file of newFiles) {
      const arrayBuffer = await file.arrayBuffer();
      const uint8Array = new Uint8Array(arrayBuffer);
      const result = find(uint8Array);
      const mimeType = result.length > 0 ? result[0].mime : null;

      if (mimeType === "video/x-matroska") {
        toast.error("Les fichiers MKV ne sont pas autorisés", {
          position: "top-center",
          autoClose: 3000,
        });
      } else if (selectedFiles.length + newFiles.length > 4) {
        toast.error("Veuillez mettre au maximum 4 médias", {
          position: "top-center",
          autoClose: 3000,
        });
      } else {
        setSelectedFiles([...selectedFiles, file]);
      }
    }
  };

  const handleFileChange = async (file: File) => {
    setIsLoadingVideo(true);

    const arrayBuffer = await file.arrayBuffer();
    const uint8Array = new Uint8Array(arrayBuffer);
    const result = find(uint8Array);
    const mimeType = result.length > 0 ? result[0].mime : null;

    setIsLoadingVideo(false);

    if (mimeType === "video/x-matroska") {
      toast.error("Les fichiers MKV ne sont pas autorisés", {
        position: "top-center",
        autoClose: 3000,
      });
      return;
    }

    const video = document.createElement("video");
    video.preload = "metadata";
    video.src = URL.createObjectURL(file);

    video.onloadedmetadata = () => {
      URL.revokeObjectURL(video.src);
      const duration = video.duration;
      const fileSize = file.size;

      if (duration > 30) {
        toast.error("La vidéo doit être de moins de 30 secondes");
      } else if (fileSize > 50 * 1024 * 1024) {
        toast.error("La taille du fichier doit être inférieure à 50 Mo");
      } else {
        setSelectedFile(file);
      }
    };
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
    setFileInputDisabled(true);
    setTimeout(() => {
      setFileInputDisabled(false);
    }, 100);
  };

  const handleDeleteClick = (index: number) => {
    const newSelectedFiles = [...selectedFiles];
    newSelectedFiles.splice(index, 1);
    setSelectedFiles(newSelectedFiles);
  };

  const handlePost = async () => {
    const content = textareaRef.current?.value;
    if (isCreatingPost) {
      if (content) {
        setIsLoading(true);
        try {
          const data = await createPost(
            content,
            selectedGame?.id || "",
            selectedFiles,
          );
          toast.success("Post créé avec succès !");
        } catch (error) {
          console.error(error);
          toast.error("Échec de la création du post");
          setIsLoading(false);
        }
      } else {
        toast.error("Le contenu du post ne peut pas être vide");
      }
    } else {
      const name = title;

      if (!selectedGame) {
        toast.error("Vous devez sélectionner un jeu.");
        return;
      }

      if (selectedGame && selectedFile) {
        setIsLoading(true);
        try {
          const data = await createGameplay(
            name,
            "",
            selectedFile,
            selectedGame.id,
            selectedUserIds, // Pass the selected user IDs
          );
          toast.success("Gameplay créé avec succès !");
        } catch (error) {
          console.error(error);
          toast.error("Échec de la création du gameplay");
        } finally {
          setIsLoading(false);
        }
      } else {
        toast.error(
          "Le titre du gameplay, le jeu et le fichier ne peuvent pas être vides",
        );
      }
    }
  };

  const handleTextareaChange = () => {
    const content = textareaRef.current?.value;
    setIsContentEmpty(!content);
  };

  const encodeUsername = (username: string) => {
    return encodeURIComponent(
      username.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
    );
  };

  const isPseudoVerified = () => {
    if (!selectedGame) return false;
    const ownedGame = ownedGames.find((game) => game.id === selectedGame.id);
    return selectedGame.hasGame && ownedGame && !ownedGame.isPseudoVerified;
  };

  const toggleCreateMode = () => {
    setIsCreatingPost(!isCreatingPost);
  };

  const postButtons = [
    {
      title: isCreatingPost ? (
        <div className="flex gap-x-1">
          <div className="rounded-full bg-[rgba(255,255,255,0.3)] p-1">
            <SwapIcon />
          </div>
          Post
        </div>
      ) : (
        <div className="flex items-center gap-x-1">
          <SwapIcon />
          Gameplay
        </div>
      ),
      path: "#",
    },
  ];

  return (
    <div className="flex w-full max-w-[610px] flex-col gap-y-4 rounded-[20px] border-[0.5px] border-[rgba(198,182,213,0.65)] bg-[rgba(245,235,255,0.10)] px-5 pb-5 pt-2 max-mobile:hidden">
      <div className="flex w-full justify-between">
        <div className="flex items-center gap-x-4 self-stretch">
          <div className="relative">
            <AvatarIcon logo={auth?.user?.logo} className="avatar-xs" />
            {selectedGame ? (
              <div className="game-thumbnail-xxs absolute bottom-[-7px] left-[23px] border-[0.5px] border-[rgba(198,182,213,0.50)] shadow-[-1px_-1px_4px_rgba(0,0,0,0.25)]">
                <div
                  className="z-1 flex h-[22px] w-[22px] items-start rounded-[4px]"
                  style={{
                    background: `url(${selectedGame.logo}) 50% / cover no-repeat`,
                  }}
                ></div>
              </div>
            ) : null}
          </div>
          <div className="flex flex-col">
            <div className="flex items-center gap-x-2 self-stretch">
              <p className="h4-mobile cursor-pointer self-stretch">
                {getDisplayName(auth.user, selectedGame)}
              </p>
              {isPseudoVerified() && <UnVerifiedIconFrame />}
            </div>
            <p className="label-s-400 cursor-pointer opacity-90">
              {selectedGame?.name}
            </p>
          </div>
        </div>
        <div
          className={`${isCreatingPost ? "pr-3" : "pl-3"} inline-flex h-[35px] cursor-pointer items-center justify-center gap-x-[10px] rounded-full border-[0.5px] border-[rgba(248,239,251,0.60)] transition-all duration-300 hover:bg-[rgba(255,255,255,0.15)]`}
          onClick={toggleCreateMode}
        >
          {isCreatingPost && (
            <div className="flex translate-x-0 transform items-center rounded-full bg-[rgba(255,255,255,0.10)] p-[10px] transition-transform duration-300 hover:bg-[rgba(255,255,255,0.20)]">
              <SwapIcon />
            </div>
          )}
          <p
            className={`nav-menu-500 whitespace-nowrap transition-opacity duration-300 ${isCreatingPost ? "opacity-100" : "hidden opacity-0"}`}
          >
            Talk
          </p>
          <p
            className={`nav-menu-500 whitespace-nowrap transition-opacity duration-300 ${isCreatingPost ? "hidden opacity-0" : "opacity-100"}`}
          >
            Play
          </p>
          {!isCreatingPost && (
            <div className="flex translate-x-0 transform items-center rounded-full bg-[rgba(255,255,255,0.10)] p-[10px] transition-transform duration-300 hover:bg-[rgba(255,255,255,0.20)]">
              <SwapIcon />
            </div>
          )}
        </div>
      </div>

      {isCreatingPost ? (
        <div className="z-[10] flex flex-col items-start gap-y-2 self-stretch rounded-[20px] bg-[rgba(245,235,255,0.10)] px-5 pb-5 pt-2 shadow-[inset_0_1px_2px_rgba(0,0,0,0.25)] backdrop-blur-[10.5px]">
          <div className="flex flex-col items-start gap-y-2 self-stretch">
            <div className="flex w-full">
              <TextareaAutosize
                ref={textareaRef}
                placeholder={placeholder}
                maxRows={7}
                className="label-m-400 mb-[1px] flex grow select-text resize-none self-stretch break-words bg-transparent pb-2 pt-2 opacity-50 focus:opacity-100 focus:outline-none"
                style={{ minHeight: "20px", maxHeight: "180px" }}
                onChange={handleTextareaChange}
              />
            </div>
            <div className="flex w-full gap-x-2 overflow-x-auto">
              {selectedFiles.slice(0, 4).map((file, index) => (
                <MediaThumbnail
                  isVideo={file.type.includes("video")}
                  key={index}
                  banner={URL.createObjectURL(file)}
                  size="m"
                  showSelectionIndicator={true}
                  onClick={() => handleDeleteClick(index)}
                />
              ))}
            </div>
          </div>
          <div className="z-[1000] flex flex-col items-start gap-y-1 self-stretch">
            <p className="label-s-600">Jeu</p>
            <SelectGame
              items={mergedGames}
              onSelect={(game) => setSelectedGame(game)}
              selectedItem={selectedGame}
            />
          </div>
        </div>
      ) : (
        <div className="z-[10] flex flex-col items-start gap-y-4 self-stretch rounded-[20px] bg-[rgba(245,235,255,0.10)] px-5 pb-5 pt-2 shadow-[inset_0_1px_2px_rgba(0,0,0,0.25)] backdrop-blur-[10.5px]">
          <div className="flex flex-col items-start gap-y-1 self-stretch">
            <p className="label-s-600">Jeu (Obligatoire)</p>
            <SelectGame
              items={mergedGames}
              onSelect={(game) => setSelectedGame(game)}
              selectedItem={selectedGame}
            />
          </div>
          <div className="flex flex-col items-start gap-y-1 self-stretch">
            <p className="label-s-600">Titre (Obligatoire)</p>
            <CustomTextbox
              placeholder="Ajoute un titre"
              value={title}
              onChange={(name: string) => setTitle(name)}
              maxLength={80}
              validationOption={{
                name: "Titre",
                check: true,
                required: true,
              }}
            />
          </div>

          {selectedGame && (
            <PlayerSearch
              gameId={selectedGame.id}
              onSelectUsers={(userIds) => setSelectedUserIds(userIds)}
            />
          )}

          <label className="block w-full cursor-pointer rounded-[20px] border-[2.5px] border-dashed border-[rgba(198,182,213,0.65)] bg-[rgba(245,235,255,0.10)] p-4 text-center shadow-[inset_0_1px_2px_rgba(0,0,0,0.25)] backdrop-blur-[10.5px]">
            <div className="flex h-full w-full flex-col items-center justify-center gap-y-1">
              <input
                type="file"
                accept="video/*"
                className="hidden"
                ref={fileInputRef}
                disabled={fileInputDisabled}
                onChange={(e) => {
                  const file = e.target.files?.[0];
                  if (file) {
                    handleFileChange(file);
                  }
                }}
              />
              {isLoadingVideo ? (
                <div className="flex h-full items-center justify-center">
                  <Loading />
                </div>
              ) : selectedFile ? (
                <div className="z-1 relative">
                  <video
                    src={URL.createObjectURL(selectedFile)}
                    className="z-1 rounded-lg max-mobile:max-w-[80vw]"
                    controls
                  />
                  <button
                    onClick={handleRemoveFile}
                    className="bg-red-500 hover:bg-red-700 absolute right-2 top-2 z-10 rounded-full p-1 text-white focus:outline-none"
                  >
                    <IoMdClose size={16} />
                  </button>
                </div>
              ) : (
                <div className="flex h-[20vh] flex-col items-center justify-center gap-y-4">
                  <AddFillIcon />
                  <p className="paragraph-s-500">
                    Choisi une{" "}
                    <span className="underline">
                      vidéo de moins de 30 secondes
                    </span>{" "}
                    à ajouter
                  </p>
                </div>
              )}
            </div>
          </label>
        </div>
      )}
      <div
        className={`${isCreatingPost ? "justify-between" : "justify-end"} flex w-full`}
      >
        {isCreatingPost && (
          <>
            <div onClick={handleFileInputClick}>
              <ImagesIcon />
            </div>
            <input
              type="file"
              accept="image/*,video/*"
              ref={fileInputRef}
              onChange={handleFileInputChange}
              multiple
              className="hidden"
            />
          </>
        )}
        <SecondaryButton
          label="Poster"
          onClick={handlePost}
          disabled={
            isCreatingPost
              ? isContentEmpty || isLoading
              : !title || !selectedGame || !selectedFile
          }
        />
      </div>
      {isLoading && (
        <div className="flex w-full justify-center">
          <Loading />
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

import React, { useState, useEffect, useRef } from "react";
import {
  BackButton,
  SecondaryButton,
  SelectGame,
  SubtitleNavigation,
  AddFillIcon,
  Loading,
  AvatarIcon,
  UnVerifiedIconFrame,
  CustomTextbox,
  PlayerSearch,
} from "../../components";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createGameplay } from "../../helpers/fetchGameplay";
import { Game } from "../../types";
import { fetchAllGames, fetchOwnedGames } from "../../helpers/fetchGame";
import { useAuth } from "../../providers/userAuth";
import { useLocation, useNavigate } from "react-router-dom";
import { IoMdClose } from "react-icons/io";
import { getDisplayName } from "../../utils/getDisplayName";
import find from "magic-bytes.js";

const postButtons = [
  { title: "Talk", path: "/createtalk" },
  { title: "Play", path: "/createplay" },
];

interface GameplayResponse {
  game_id: string;
  comment: string;
  name: string;
  user_id: string;
  id: string;
  created_at: string;
  file: string;
}

export const CreateGameplay = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isLoadingVideo, setIsLoadingVideo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [fileInputDisabled, setFileInputDisabled] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [title, setTitle] = useState<string>("");
  const [postResponse, setPostResponse] = useState<GameplayResponse | null>(
    null,
  );
  const [allGames, setAllGames] = useState<Game[]>([]);
  const [ownedGames, setOwnedGames] = useState<Game[]>([]);
  const [mergedGames, setMergedGames] = useState<Game[]>([]);
  const [selectedGame, setSelectedGame] = useState<Game | undefined>(undefined);
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]); // New state for selected user IDs

  const auth = useAuth();
  const navigate = useNavigate();

  const encodeUsername = (username: string) => {
    return encodeURIComponent(
      username.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
    );
  };

  useEffect(() => {
    const loadGames = async () => {
      try {
        const gamesList = await fetchAllGames();
        const ownedGamesList = await fetchOwnedGames(
          encodeUsername(auth.user.username),
        );

        const allGamesWithHasGame = gamesList.map((game) => ({
          ...game,
          hasGame: false,
        }));

        const ownedGamesWithHasGame = ownedGamesList.map((game) => ({
          ...game,
          hasGame: true,
        }));

        setAllGames(allGamesWithHasGame);
        setOwnedGames(ownedGamesWithHasGame);

        const mergedGames = [
          ...ownedGamesWithHasGame,
          ...allGamesWithHasGame.filter(
            (game) =>
              !ownedGamesWithHasGame.find(
                (ownedGame) => ownedGame.id === game.id,
              ),
          ),
        ];
        setMergedGames(mergedGames);
      } catch (error) {
        console.error("Failed to fetch games:", error);
        setIsError(true);
        setError("Failed to fetch games. Please try again later.");
      }
    };

    loadGames();
  }, [auth]);

  const handleFileChange = async (file: File) => {
    setIsLoadingVideo(true);

    const arrayBuffer = await file.arrayBuffer();
    const uint8Array = new Uint8Array(arrayBuffer);
    const result = find(uint8Array);
    const mimeType = result.length > 0 ? result[0].mime : null;

    setIsLoadingVideo(false);

    if (!mimeType?.startsWith("video/") || mimeType === "video/x-matroska") {
      toast.error("Veuillez télécharger un fichier vidéo valide");
      return;
    }

    const video = document.createElement("video");
    video.preload = "metadata";
    video.src = URL.createObjectURL(file);

    video.onloadedmetadata = () => {
      URL.revokeObjectURL(video.src);
      const duration = video.duration;
      const fileSize = file.size;

      if (duration > 30) {
        toast.error("La vidéo doit être de moins de 30 secondes");
      } else if (fileSize > 50 * 1024 * 1024) {
        toast.error("La taille du fichier doit être inférieure à 50 Mo");
      } else {
        setSelectedFile(file);
      }
    };
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
    setFileInputDisabled(true);
    setTimeout(() => {
      setFileInputDisabled(false);
    }, 100);
  };

  const handlePost = async () => {
    const name = title;

    if (!selectedGame) {
      toast.error("Vous devez sélectionner un jeu.");
      return;
    }

    if (selectedGame && selectedFile) {
      setIsLoading(true);
      setIsError(false);
      setError(null);
      try {
        const data = await createGameplay(
          name,
          "",
          selectedFile,
          selectedGame.id,
          selectedUserIds, // Pass selected user IDs here
        );
        setPostResponse(data);
        toast.success("Gameplay créé avec succès !");
      } catch (error) {
        console.error(error);
        toast.error("Échec de la création du gameplay");
        setIsError(true);
        setError("Failed to create gameplay. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    } else {
      toast.error(
        "Le titre du gameplay, le jeu et le fichier ne peuvent pas être vides",
      );
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLLabelElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent<HTMLLabelElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      handleFileChange(file);
    }
  };

  const isPseudoVerified = () => {
    if (!selectedGame) return false;
    const ownedGame = ownedGames.find((game) => game.id === selectedGame.id);
    return selectedGame.hasGame && ownedGame && !ownedGame.isPseudoVerified;
  };

  const isPostButtonDisabled = !title || !selectedGame || !selectedFile;

  return (
    <div className="flex min-h-[100vh] w-full flex-col items-center">
      <div className="flex w-full max-w-[680px] flex-col gap-y-4 px-5 pt-[40px]">
        <div className="flex items-center justify-between self-stretch">
          <BackButton />
        </div>
        <div className="flex flex-col items-start gap-y-4 self-stretch">
          <div className="flex w-full items-center justify-between">
            <div className="flex items-start gap-x-2">
              <SubtitleNavigation buttons={postButtons} secondary={true} />
            </div>
            <div>
              <SecondaryButton
                label="Poster"
                onClick={handlePost}
                disabled={isPostButtonDisabled}
              />
            </div>
          </div>
          <div className="flex w-full flex-col gap-y-4">
            <div className="flex items-center gap-x-4 self-stretch">
              <div className="relative">
                <AvatarIcon logo={auth?.user?.logo} className="avatar-xs" />
                {selectedGame ? (
                  <div className="game-thumbnail-xxs absolute bottom-[-7px] left-[23px] border-[0.5px] border-[rgba(198,182,213,0.50)] shadow-[-1px_-1px_4px_rgba(0,0,0,0.25)]">
                    <div
                      className="z-1 flex h-[22px] w-[22px] items-start rounded-[4px]"
                      style={{
                        background: `url(${selectedGame.logo}) 50% / cover no-repeat`,
                      }}
                    ></div>
                  </div>
                ) : null}
              </div>
              <div className="flex flex-col">
                <div className="flex items-center gap-x-2 self-stretch">
                  <p className="h4-mobile cursor-pointer self-stretch">
                    {getDisplayName(auth.user, selectedGame)}
                  </p>
                  {isPseudoVerified() && <UnVerifiedIconFrame />}
                </div>
                <p className="label-s-400 cursor-pointer opacity-90">
                  {selectedGame?.name}
                </p>
              </div>
            </div>
            <div className="flex flex-col items-start gap-y-1 self-stretch">
              <p className="label-s-600">Jeu (Obligatoire)</p>
              <SelectGame
                items={mergedGames}
                onSelect={(game) => {
                  setSelectedGame(game);
                }}
                selectedItem={selectedGame}
              />
            </div>
            <div className="flex flex-col items-start gap-y-1 self-stretch">
              <p className="label-s-600">Titre (Obligatoire)</p>
              <CustomTextbox
                placeholder="Ajoute un titre"
                value={title}
                onChange={(name: string) => {
                  setTitle(name);
                }}
                maxLength={80}
                validationOption={{
                  name: "Titre",
                  check: true,
                  required: true,
                }}
              />
            </div>
            {selectedGame && (
              <PlayerSearch
                gameId={selectedGame.id}
                onSelectUsers={(userIds) => setSelectedUserIds(userIds)}
              />
            )}
            <label
              className="block cursor-pointer rounded-[20px] border-[2.5px] border-dashed border-[rgba(198,182,213,0.65)] bg-[rgba(245,235,255,0.10)] p-4 text-center shadow-[inset_0_1px_2px_rgba(0,0,0,0.25)] backdrop-blur-[10.5px]"
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <div className="flex h-full flex-col items-center justify-center gap-y-1">
                <input
                  type="file"
                  accept="video/*"
                  className="hidden"
                  ref={fileInputRef}
                  disabled={fileInputDisabled}
                  onChange={(e) => {
                    const file = e.target.files?.[0];
                    if (file) {
                      handleFileChange(file);
                    }
                  }}
                />
                {isLoadingVideo ? (
                  <div className="flex h-full items-center justify-center">
                    <Loading />
                  </div>
                ) : selectedFile ? (
                  <div className="z-1 relative">
                    <video
                      src={URL.createObjectURL(selectedFile)}
                      className="z-1 rounded-lg max-mobile:max-w-[80vw] min-745:max-h-[350px] min-745:max-w-[600px]"
                      controls
                    />
                    <button
                      onClick={handleRemoveFile}
                      className="bg-red-500 hover:bg-red-700 absolute right-2 top-2 z-10 rounded-full p-1 text-white focus:outline-none"
                    >
                      <IoMdClose size={16} />
                    </button>
                  </div>
                ) : (
                  <div className="flex h-[20vh] flex-col items-center justify-center gap-y-4">
                    <AddFillIcon />
                    <p className="paragraph-s-500">
                      Dépose ou choisi une{" "}
                      <span className="underline">
                        vidéo de moins de 30 secondes
                      </span>{" "}
                      à ajouter
                    </p>
                  </div>
                )}
              </div>
            </label>
            <div className="flex items-center justify-between">
              <p className="paragraph-s-500 max-mobile:hidden">
                Formats supportés : mp4, mov, avi, wmv
              </p>
              <p className="paragraph-s-500 max-mobile:hidden">
                Poids max: 50Mo
              </p>
              <p className="paragraph-s-500 min-745:hidden">
                Formats supportés : <br /> mp4, mov, avi, wmv
              </p>
              <p className="paragraph-s-500 min-745:hidden">
                Poids max: <br /> 50Mo
              </p>
            </div>
            {isLoading && (
              <div className="flex w-full justify-center">
                <Loading />
              </div>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../providers/userAuth";
import { fetchUsernameExist } from "../../helpers/fetchUser";
import {
  ButtonSimple,
  DiscordButton,
  SimpleInput,
  PreregisterCard,
  TransparentSecondaryButton,
  Loading,
  LogoBeta,
} from "../../components";
import { UserExist } from "../../types";

export const SignIn: React.FC = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [input, setInput] = useState({
    login: "",
    password: "",
    email: "",
  });
  const [error, setError] = useState<string | null>(null);
  const [isEmailValid, setIsEmailValid] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigateInscription = () => {
    navigate(`/inscription`);
  };

  const handleSignIn = async () => {
    if (!input.login || !input.password) {
      setError("Veuillez saisir un nom d'utilisateur et un mot de passe.");
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const userExistData: UserExist = await fetchUsernameExist(input.login)();
      if (userExistData.exists) {
        const response = await auth.loginAction(input);
        if (response === true) {
          navigate("/home");
        } else {
          setError("Nom d'utilisateur ou mot de passe incorrect.");
        }
      } else {
        setError("Mail ou pseudo inconnu. Veuillez vérifier et réessayer.");
      }
    } catch (error) {
      setError(
        "Une erreur s'est produite lors de la connexion. Veuillez réessayer.",
      );
    } finally {
      setIsLoading(false);
    }
  };

  const validateEmail = (email: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (name === "email") {
      setIsEmailValid(validateEmail(value));
    }
  };

  const navigateLostPassword = () => {
    navigate(`/forgot-password`);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handleSignIn();
    }
  };

  return (
    <div className="relative flex min-h-[100vh] flex-col items-center gap-y-8 max-mobile:min-w-[100svw] max-mobile:pb-[100px] max-mobile:pt-10 min-745:w-full min-745:max-w-[480px] min-745:pt-[6vh]">
      {isLoading && <Loading />}
      <div className="flex flex-col items-center justify-center gap-y-2 self-stretch">
        <p className="h1-mobile">Bienvenue dans le monde Gaming</p>
        <LogoBeta />
      </div>
      <div className="flex w-full flex-col items-start gap-y-4 px-5 max-mobile:max-w-[550px] min-745:min-w-[390px]">
        <div className="flex flex-col items-start gap-y-6 self-stretch">
          <div className="flex flex-col items-start gap-y-2 self-stretch">
            <p className="nav-menu-700">Pseudo / Adresse mail</p>
            <SimpleInput
              iconType="none"
              placeholder="Saisi ton pseudo TalkPlay"
              value={input.login}
              onChange={(e) => handleInput(e)}
              name="login"
              onKeyDown={handleKeyDown}
            />
          </div>
          <div className="flex flex-col items-start gap-y-2 self-stretch">
            <p className="nav-menu-700">Mot de passe</p>
            <SimpleInput
              iconType="eyes"
              placeholder="Saisi ton mot de passe"
              value={input.password}
              onChange={(e) => handleInput(e)}
              name="password"
              onKeyDown={handleKeyDown}
            />
            <p
              className="link-s cursor-pointer opacity-60 hover:opacity-100"
              onClick={navigateLostPassword}
            >
              Mot de passe oublié ?
            </p>
          </div>
          <ButtonSimple
            label="Se connecter"
            plusFonction={false}
            onClick={handleSignIn}
          />
          {error && (
            <span className="nav-menu-500 flex w-full justify-center text-red">
              {error}
            </span>
          )}
          <DiscordButton />
        </div>
        <span
          className="h-[1px] w-full"
          style={{
            background:
              "linear-gradient(90deg, rgba(248, 239, 251, 0.00) 0%, #F8EFFB 47.5%, rgba(248, 239, 251, 0.00) 100%)",
          }}
        />
        <div className="flex w-full flex-col gap-y-4">
          <TransparentSecondaryButton
            label="Créer un compte"
            onClick={navigateInscription}
            noBg={true}
            size="h-12"
          />
          <p className="paragraph-m-400 self-stretch text-center">
            Pas encore de compte ? Inscrivez-vous en un éclair et rejoignez la
            communauté TalkPlay.
          </p>
        </div>
        {/* <PreregisterCard
          email={input.email}
          onEmailChange={handleInput}
          isEmailValid={isEmailValid}
          onPreRegisterClick={onPreRegisterClick}
        /> */}
      </div>
    </div>
  );
};

import { useEffect, useState } from "react";
import {
  BackButton,
  BottomConnectGames,
  ButtonSimple,
  GameDetailCard,
  InputSearch,
  Loading,
  PlatformCard,
  StepCard,
} from "../../../components";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../providers/userAuth";
import { Game, GameSearchResult, Launcher } from "../../../types";
import { fetchAllGames } from "../../../helpers/fetchGame";
import {
  fetchAllLaunchers,
  fetchGamesLauncher,
  linkLauncher,
} from "../../../helpers/fetchLauncher";
import { useRouteOrigin } from "../../../contexts/RouteOriginContext";
import { toast } from "react-toastify";
import { steamAuthUrl } from "../../../constants/api";

export const ConnectGames = () => {
  const navigate = useNavigate();
  const { token } = useParams<{ token: string }>();
  const auth = useAuth();
  const [games, setGames] = useState<Game[]>([]);
  const [launchers, setLaunchers] = useState<Launcher[]>([]);
  const [launcherGames, setLauncherGames] = useState<{
    [key: string]: GameSearchResult[];
  }>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { fromRegistration } = useRouteOrigin();
  const convertGameNameToUrl = (gameName: string) => {
    return gameName.replace(/\s+/g, "").toLowerCase();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedGames = await fetchAllGames();
        const fetchedLaunchers = await fetchAllLaunchers();
        setGames(fetchedGames);
        setLaunchers(fetchedLaunchers);

        const launcherGamesData: { [key: string]: GameSearchResult[] } = {};
        for (const launcher of fetchedLaunchers) {
          const games = await fetchGamesLauncher(launcher.id);
          launcherGamesData[launcher.id] = games;
        }
        setLauncherGames(launcherGamesData);
      } catch (err) {
        setError("Erreur lors de la récupération des données.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (token) {
      const linkSteamLauncher = async () => {
        try {
          const steamLauncher = launchers.find(
            (launcher) => launcher.name === "Steam",
          );
          if (steamLauncher) {
            await linkLauncher(steamLauncher.id, token);
            toast.success("Steam account linked successfully!");
            updateGameListAfterLink(steamLauncher.id);
            navigate("/connectgames");
          }
        } catch (error) {
          toast.error("Failed to link Steam account.");
          console.error("Error linking Steam account:", error);
        }
      };

      linkSteamLauncher();
    }
  }, [token, launchers, navigate]);

  const updateGameListAfterLink = async (launcherId: string) => {
    const updatedGames = await fetchAllGames();
    setGames(updatedGames);

    const updatedLauncherGames = await fetchGamesLauncher(launcherId);
    setLauncherGames((prevLauncherGames) => ({
      ...prevLauncherGames,
      [launcherId]: updatedLauncherGames,
    }));
  };

  const handleConnect = (publisherName: string, launcherId: string) => {
    if (publisherName === "Steam") {
      window.location.href = steamAuthUrl;
    }
    if (publisherName === "Riot Games") {
      navigate("/connectgames/riot", { state: { launcherId } });
    }
  };

  const handleGameClick = (game: Game) => {
    const gameUrl = convertGameNameToUrl(game.name);
    navigate(`/connectgames/${gameUrl}`, { state: { gameId: game.id } });
  };

  const navigateToHome = () => {
    navigate(`/`);
  };

  const handleGameConnect = (game: Game) => {
    const linkedLauncher = game.launchers.find(
      (launcher) => launcher.name === "Steam" || launcher.name === "Riot Games",
    );
    if (linkedLauncher) {
      handleConnect(linkedLauncher.name, linkedLauncher.id);
    } else {
      handleGameClick(game);
    }
  };

  const gamesWithoutLaunchers = games.filter(
    (game) =>
      !game.hasGame &&
      game.name !== "Squad Busters" &&
      (game.launchers.length === 0 ||
        (game.launchers.some((launcher) => launcher.name === "Supercell ID") &&
          ["Clash of Clans", "Brawl Stars", "Clash Royale"].includes(
            game.name,
          ))) &&
      !game.platforms.some((platform) => platform.name === "Switch") &&
      !game.launchers.some(
        (launcher) =>
          launcher.name === "Steam" || launcher.name === "Riot Games",
      ),
  );

  const availableLaunchers = launchers.filter(
    (launcher) =>
      !launcher.hasLauncher &&
      !["Epic Games", "Supercell ID"].includes(launcher.name),
  );

  const launcherAvailableGames = availableLaunchers.map((launcher) => {
    const totalGames = launcherGames[launcher.id]?.filter(
      (game) =>
        !games.some((userGame) => userGame.id === game.id && userGame.hasGame),
    ).length;
    return {
      ...launcher,
      availableGames: totalGames || 0,
    };
  });

  const handleInputSearchFocus = () => {
    setIsSearching(true);
  };

  const handleInputSearchChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value;
    setSearchQuery(value);
    if (value === "") {
      setIsSearching(false);
    } else {
      setIsSearching(true);
    }
  };

  const filteredGames = games.filter(
    (game) =>
      (game.name &&
        game.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (game.single &&
        game.single.toLowerCase().includes(searchQuery.toLowerCase())),
  );

  return (
    <div className="flex w-full flex-col gap-y-8 overflow-y-auto max-mobile:h-screen max-mobile:pb-[170px] min-745:h-screen min-745:min-w-[400px]">
      <div className="pl-5 pt-10">
        <BackButton
          onClick={() => navigate("/home")}
          label="Retour à l'acceuil"
        />
      </div>

      <div className="flex flex-col gap-y-8 px-5 max-400:px-2 min-825:px-10">
        {fromRegistration && <StepCard currentStep={2} />}
        <div className="flex w-full items-center justify-between gap-x-1">
          <p className="paragraph-m-400 content-center self-stretch">
            Relie ton compte Talk Play à tes jeux pour une expérience optimale
          </p>
          <ButtonSimple
            label="Terminer"
            onClick={navigateToHome}
            size="max-mobile:hidden"
          />
        </div>
      </div>
      <div className="flex max-w-[700px] flex-col gap-y-2 px-5 max-400:px-2 min-825:px-10">
        <p className="nav-menu-700">Rechercher un jeu</p>
        <InputSearch
          showLogoSection={false}
          placeholder="Rechercher un jeu..."
          onClick={handleInputSearchFocus}
          onChange={handleInputSearchChange}
          value={searchQuery}
        />
      </div>
      {loading && <Loading />}
      {error && <div className="h1-mobile">{error}</div>}
      {isSearching ? (
        <div className="flex w-full max-w-[700px] flex-col gap-y-6 px-5 max-400:px-2 min-825:px-10">
          <div className="flex w-full flex-col gap-y-4">
            <p className="h2-mobile">Jeux</p>
            <div className="flex flex-col gap-y-2">
              {filteredGames.length > 0 ? (
                filteredGames.map((game) => (
                  <GameDetailCard
                    key={game.id}
                    card={game.card}
                    name={game.name}
                    studioName={
                      game.studios && game.studios[0]
                        ? game.studios[0].name
                        : "Sans studio"
                    }
                    platforms={
                      game.platforms && game.platforms[0]
                        ? [game.platforms[0].name]
                        : ["Sans platform"]
                    }
                    onConnect={() => handleGameConnect(game)}
                    isConnected={game.hasGame}
                    isClickable={false}
                  />
                ))
              ) : (
                <p className="h4-mobile">
                  Aucun jeu trouvé. Pense à voter sur Discord pour en ajouter de
                  nouveaux !
                </p>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex w-full max-w-[700px] flex-col gap-y-6 px-5 max-400:px-2 min-825:px-10">
          <div className="flex w-full flex-col gap-y-4">
            <p className="h2-mobile">Plateformes</p>
            <div className="flex gap-x-4">
              {launcherAvailableGames.length > 0 ? (
                launcherAvailableGames.map((launcher) => (
                  <div className="flex flex-col items-center gap-y-1">
                    <PlatformCard
                      key={launcher.id}
                      logo={launcher.logo}
                      name={launcher.name}
                      gameCount={launcher.availableGames}
                      isConnected={launcher.hasLauncher}
                      onClick={() => handleConnect(launcher.name, launcher.id)}
                    />
                    <p className="nav-menu-700">{launcher.name}</p>
                  </div>
                ))
              ) : (
                <p className="h1-mobile">
                  Il n'y a plus de plateformes à connecter de disponible. Pense
                  à voter sur Discord pour en ajouter de nouveaux !
                </p>
              )}
            </div>
          </div>
          <div className="flex w-full flex-col gap-y-4">
            <p className="h2-mobile">Jeux sans plateforme</p>
            <div className="flex flex-col gap-y-2">
              {gamesWithoutLaunchers.length > 0 ? (
                gamesWithoutLaunchers.map((game) => (
                  <GameDetailCard
                    key={game.id}
                    card={game.card}
                    name={game.name}
                    studioName={
                      game.studios && game.studios[0]
                        ? game.studios[0].name
                        : "Sans studio"
                    }
                    platforms={
                      game.platforms && game.platforms[0]
                        ? [game.platforms[0].name]
                        : ["Sans platform"]
                    }
                    onConnect={() => handleGameConnect(game)}
                    isConnected={game.hasGame}
                    isClickable={false}
                  />
                ))
              ) : (
                <p className="h4-mobile">
                  Il n'y a plus de jeux sans plateforme disponibles. Pense à
                  voter sur Discord pour en ajouter de nouveaux !
                </p>
              )}
            </div>
          </div>
        </div>
      )}
      {/* <BottomConnectGames
        navigateTo={navigateToHome}
        addMoreGames={() => navigate(`/`)}
        selectedAccountCount={
          availableLaunchers.length + gamesWithoutLaunchers.length
        }
      /> */}
    </div>
  );
};
